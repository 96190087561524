import { Component, OnInit } from '@angular/core';
import { PollService } from '../poll.service';
import { Observable } from 'rxjs';
import { Poll, PollWithVoteCount } from '../models/NSwagGeneratedClasses';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PageEvent } from '@angular/material';
import { DialogboolComponent } from '../dialogbool/dialogbool.component';
import { AppRoutingModule } from '../app-routing.module';
import { RouterModule, Router } from '@angular/router';

@Component({
  selector: 'app-polls',
  templateUrl: './polls.component.html',
  styleUrls: ['./polls.component.css']
})
export class PollsComponent implements OnInit {

  pollEditorUrl: string = "polls/";

  polls: Observable<PollWithVoteCount>;
  term: string = "";
  orderby: string = "";
  orderdirection: number = 2;

  pagesize: number = 10;
  page: number = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  numberofpages;
  numberofallitems: number;

  paginationHandler(event: PageEvent) {
    //if (page == 0 && this.page < this.numberofpages) {
    //  this.page++;
    //  this.getArticles();
    //}
    //else if (page == -1 && this.page > 1) {
    //  this.page--;
    //  this.getArticles();
    //}
    //else {
    //  this.page = page;
    //  this.getArticles();
    //}
    this.page = event.pageIndex;
    this.pagesize = event.pageSize;
    this.getPolls();
  }

  getPolls() {
    this.pollservice.getPolls(this.term, this.pagesize, this.orderby, this.orderdirection, this.page, res => {
      if (res.ok) {        
        console.log(this.polls);
        this.polls = res.body.results;
        this.numberofpages = Math.ceil(res.body.count / this.pagesize);
        this.numberofallitems = res.body.count;
      }
    });
  }

  openDialog(poll: PollWithVoteCount): void {
    const dialogRef = this.dialog.open(DialogboolComponent, {
      width: '250px',
      data: {
        text: "Biztos, hogy törölni szeretné az alábbi szavazást?: " + poll.title + (poll.voteCount > 0 ? " A szavazásra már érkezett " + poll.voteCount + " szavazat" : ""), id: poll.id,
        result: false, truetext: "Törlés", falsetext: "Mégsem"
      }
    });

    dialogRef.componentInstance.answerSelected.subscribe((res) => {
      console.log(res);
      dialogRef.close();
    });
  }

  openPollForEdit(Id: number) {
    this.router.navigateByUrl(this.pollEditorUrl + Id);
  }

  constructor(private pollservice: PollService, public dialog: MatDialog, private router: Router) { }

  ngOnInit() {
    this.getPolls();
  }

}
