import { Component, OnInit, ElementRef, NgZone, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ArticleService } from '../article.service';
import { Article, Category, ArticleCategory, ArticleTag, RelatedArticle } from '../models/NSwagGeneratedClasses';
import { CategoryService } from '../category.service';
import { switchMap } from 'rxjs/operators';
import { MediaService } from '../media.service';
import { ToastrService } from 'ngx-toastr';
import { MatChipInputEvent } from '@angular/material';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { RelatedarticleselectorComponent } from '../relatedarticleselector/relatedarticleselector.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EditorComponent, EditorModule } from '@tinymce/tinymce-angular';
import { AuthService } from '../auth.service';
import { HttpHeaders } from '@angular/common/http';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfigurationService } from '../configuration.service';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { Observable } from 'rxjs';
import * as moment from 'moment';

export class RelatedArticleWithTitle {
  id: number;
  articleId: number;
  relatedArticleId: number;
  title: string;
}

export class ImageObject {
  extension: string;
  imagedata: Blob;
  imgAttrStart: number;
  imgAttrEnd: number;
}

@Component({
  selector: 'app-article-editor',
  templateUrl: './article-editor.component.html',
  styleUrls: ['./article-editor.component.css']
})
export class ArticleEditorComponent implements OnInit {

  article: Article = {} as Article;  
  categories: Category[];
  selectedCategories: number[] = [];
  relatedArticles: RelatedArticleWithTitle[] = [];
  bodyHTMLEditor: EditorComponent;
  imagesToUpload: ImageObject[];
  config: object;

  mainPageImageDisplay: string = 'none';
  mainPageImageDropDisplay: string = 'block';
  mainPageImageSrc: any;
  mainPageImageData: File;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];



  articleForm: FormGroup = this.fb.group({
    selectedCategories: new FormControl([]),
    title: new FormControl('', Validators.required),
    authorAlias: new FormControl(''),
    isDisplayedOnMainPage: new FormControl(false),
    commentSectionVisible: new FormControl(true),
    canComment: new FormControl(true),
    canRate: new FormControl(true),
    createdDate: new FormControl({ value: '', disabled: true }),
    publicationDate: new FormControl({ value: null, disabled: true }),
    publicationEndDate: new FormControl({ value: null, disabled: true }),
    articleTags: new FormControl([]),
    relatedArticles: new FormControl(this.relatedArticles),
    introHTML: new FormControl(''),
    bodyHTML: new FormControl('')
  }
  );

  constructor(
    private route: ActivatedRoute,
    private router: Router,    
    private articleservice: ArticleService,
    private categoryservice: CategoryService,
    public mediaservice: MediaService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private sanitazer: DomSanitizer,
    //private configurationservice: ConfigurationService,
    private imgresize: Ng2ImgMaxService) {    
  }

  getArticle(Id: number) {
    this.articleservice.getArticle(Id,
      (res) => {
        this.article = res.body;
        let categories: number[] = [];
        for (let cat of this.article.articleCategory) {
          categories.push(cat.categoryId);          
        }
        this.articleservice.getRelatedArticlesWithTitle(this.article.id, (res) => {
            this.relatedArticles = res.body;
            this.selectedCategories = categories;
            this.articleForm.setValue({
            title: this.article.title,
            selectedCategories: this.selectedCategories,
            authorAlias: this.article.authorAlias,
            isDisplayedOnMainPage: this.article.isDisplayedOnMainPage,
            commentSectionVisible: this.article.commentSectionVisible,
            canComment: this.article.canComment,
            canRate: this.article.canRate,
            createdDate: this.article.createdDate,
            publicationDate: this.article.publicationDate,
            publicationEndDate: this.article.publicationEndDate,
            articleTags: this.article.articleTags,
            relatedArticles: this.relatedArticles,
            introHTML: this.article.introHTML,
            bodyHTML: this.article.bodyHTML
          });

          if (this.article.introImageUrl !== null && this.article.introImageUrl.length > 0) {
            this.mainPageImageDisplay = 'block';
            this.mainPageImageDropDisplay = 'none';
            this.mainPageImageSrc = this.article.introImageUrl;
          }
        });        
      }
    )
  };

  containsCategory(categoryId: number): boolean {
    let catname = this.getCategoryfromId(categoryId).name;    
    return this.selectedCategories.filter(cat => cat == categoryId).length > 0;    
  }

  getCategoryfromId(id: number): Category {
    return this.categories.filter(x => x.id == id)[0];
  }

  editorSettings() {
    return {
      skin_url: '/assets/tinymce/skins/lightgray',
      inline: false,
      statusbar: false,
      browser_spellcheck: true,
      entity_encoding: 'raw',
      height: 620,
      toolbar: ['image', 'media', 'table', 'fullscreen', 'forecolor', 'backcolor', 'code', 'bold', 'underline', 'italic', 'link'],
      plugins: ['fullscreen', 'image', 'imagetools', 'table', 'paste', 'autolink', 'textcolor', 'link', 'media', 'code', 'hr'],
      image_advtab: true,
      paste_data_images: true,
      relative_urls: true,
      allow_script_urls: true,
      convert_urls: false,
      image_class_list: [
        { title: 'None', value: '' },
        { title: 'Balra igazított', value: 'article_img_left' },
        { title: 'Jobbra igazított', value: 'article_img_right' }
      ],
      contextmenu: ['link', 'image', 'bold', 'underline', 'italic'],
      language: 'hu_HU',
      //content_css: "/my-styles.css",
      color_map: [
        "#fbcf7f", "Tinivagyok kiemelőszín",
        "000000", "Black",
        "993300", "Burnt orange",
        "333300", "Dark olive",
        "003300", "Dark green",
        "003366", "Dark azure",
        "000080", "Navy Blue",
        "333399", "Indigo",
        "333333", "Very dark gray",
        "800000", "Maroon",
        "FF6600", "Orange",
        "808000", "Olive",
        "008000", "Green",
        "008080", "Teal",
        "0000FF", "Blue",
        "666699", "Grayish blue",
        "808080", "Gray",
        "FF0000", "Red",
        "FF9900", "Amber",
        "99CC00", "Yellow green",
        "339966", "Sea green",
        "33CCCC", "Turquoise",
        "3366FF", "Royal blue",
        "800080", "Purple",
        "999999", "Medium gray",
        "FF00FF", "Magenta",
        "FFCC00", "Gold",
        "FFFF00", "Yellow",
        "00FF00", "Lime",
        "00FFFF", "Aqua",
        "00CCFF", "Sky blue",
        "993366", "Red violet",
        "FFFFFF", "White",
        "FF99CC", "Pink",
        "FFCC99", "Peach",
        "FFFF99", "Light yellow",
        "CCFFCC", "Pale green",
        "CCFFFF", "Pale cyan",
        "99CCFF", "Light sky blue",
        "CC99FF", "Plum"
      ],
      table_class_list: [
        { title: 'None', value: '' },
        { title: 'Megjegyzés box', value: 'author_message' }        
      ]
    };
  }

  saveArticle() {    
    let imagesBody: ImageObject[] = [];
    let imagesIntro: ImageObject[] = [];

    try {
      this.extractImage(this.articleForm.get('bodyHTML').value, imagesBody);
      this.extractImage(this.articleForm.get('introHTML').value, imagesIntro);

    } catch (e) {
      this.toastr.warning("Nem sikerült a képek kiolvasása a tartalomból, a képek az adatbázisban kerülnek tárolásra!");
    }
    let contentBody: string = this.articleForm.get('bodyHTML').value;
    let contentIntro: string = this.articleForm.get('introHTML').value;

    if (imagesBody.length > 0 || imagesIntro.length > 0 || (this.mainPageImageData != undefined && this.mainPageImageData.size > 0)) {
      let newintroHTML: string = "";
      let newbodyHTML: string = "";
      let data: FormData = new FormData();

      let articlealias = this.article.alias.replace(/[/\\?%*:|"<>]/g, '-');

      if (this.mainPageImageData != null && this.mainPageImageData.size > 0) { 
            data.append('Files', this.mainPageImageData, articlealias + this.mainPageImageData.name.substring(this.mainPageImageData.name.length - 4));
      }        

      for (let image of imagesIntro) {
            data.append('Files', image.imagedata, articlealias + "." + image.extension);
      }

        for (let image of imagesBody) {
          
            data.append('Files', image.imagedata, articlealias + "." + image.extension);
      }            

      this.mediaservice.uploadImage(data, '', '', (res) => {
        let locations = res.body;                      

        let bodyLocations = locations.slice(locations.length - imagesBody.length);
        let introLocations;
        if (this.mainPageImageData !== undefined && this.mainPageImageData.size > 0) {
          introLocations = locations.slice(1, locations.length - imagesBody.length);
        }
        else {
          introLocations = locations.slice(0, locations.length - imagesBody.length);
        }
        
        for (let i = bodyLocations.length - 1; i >= 0; i--) {
            if (newbodyHTML.length == 0) {
              newbodyHTML = contentBody.slice(0, imagesBody[i].imgAttrStart) + bodyLocations[i] + contentBody.slice(imagesBody[i].imgAttrEnd, contentBody.length);
            }
            else {
              newbodyHTML = newbodyHTML.slice(0, imagesBody[i].imgAttrStart) + bodyLocations[i] + newbodyHTML.slice(imagesBody[i].imgAttrEnd, newbodyHTML.length);
          }
        }
        for (let i = introLocations.length - 1; i >= 0; i--) {
            if (newintroHTML.length == 0) {
              newintroHTML = contentIntro.slice(0, imagesIntro[i].imgAttrStart) + introLocations[i] + contentIntro.slice(imagesIntro[i].imgAttrEnd, contentIntro.length);
            }
            else {
              newintroHTML = newintroHTML.slice(0, imagesIntro[i].imgAttrStart) + introLocations[i] + newintroHTML.slice(imagesIntro[i].imgAttrEnd, newintroHTML.length);
            }
        }
        let mainPageImageLocations;
        if (this.mainPageImageData !== undefined && this.mainPageImageData.size > 0) {
          mainPageImageLocations = locations.slice(0);
          this.mainPageImageSrc = mainPageImageLocations[0];
        }        
        
        if (imagesBody.length > 0 && newbodyHTML.length > 0) {
          this.articleForm.patchValue({ bodyHTML: newbodyHTML });
        }

        if (imagesIntro.length > 0 && newintroHTML.length > 0) {
          this.articleForm.patchValue({ introHTML: newintroHTML });
        }        
        if (res.ok) {
          this.postArticle();
        }
        else {
          this.toastr.error("Nem sikerült a képek feltöltése, a cikk mentése sikertelen!");
        }
        
      });
    }
    else {      
      this.postArticle();
    }
    
  }

  postArticle() {
    //Kategóriák összeszedése
    this.article.articleCategory = [];
    for (let cat of this.articleForm.get('selectedCategories').value) {
      this.article.articleCategory.push({ categoryId: cat, articleId: this.article.id } as ArticleCategory);
    }

    //Kapcsolódó cikkek összeszedése
    this.article.relatedArticles = [];
    for (let relarticle of this.articleForm.get('relatedArticles').value) {
      this.article.relatedArticles.push({ id: 0, articleId: this.article.id, relatedArticleId: relarticle.relatedArticleId, relatedArticle: null } as RelatedArticle);
    }

    //formfieldek összeszedése áttöltése az articlebe
    this.article.title = this.articleForm.get('title').value;
    this.article.articleTags = this.articleForm.get('articleTags').value;
    this.article.authorAlias = this.articleForm.get('authorAlias').value;
    this.article.isDisplayedOnMainPage = this.articleForm.get('isDisplayedOnMainPage').value;
    this.article.commentSectionVisible = this.articleForm.get('commentSectionVisible').value;
    this.article.canComment = this.articleForm.get('canComment').value;
    this.article.canRate = this.articleForm.get('canRate').value;
    this.article.createdDate = moment(this.articleForm.get('createdDate').value).hours(3).toDate();
    this.article.publicationDate = moment(this.articleForm.get('publicationDate').value).hours(3).toDate();
    this.article.publicationEndDate = moment(this.articleForm.get('publicationEndDate').value).hours(3).toDate();
    this.article.bodyHTML = this.articleForm.get('bodyHTML').value;
    this.article.introHTML = this.articleForm.get('introHTML').value;
    this.article.introImageUrl = this.mainPageImageSrc;

    this.articleservice.saveArticle(this.article, (res) => {
      if (res.ok) {
        this.toastr.success("Sikeres mentés!");
        this.router.navigateByUrl('/articles');
      }
    });
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
        
    if ((value || '').trim()) {
      let articleTags = this.articleForm.get('articleTags').value;
      articleTags.push({ id: 0, articleId: this.article.id, tag: value.trim() } as ArticleTag);
      this.articleForm.patchValue({ articleTags: articleTags });
    }

    if (input) {
      input.value = '';
    }
  }

  removeTag(tag: ArticleTag) {
    let content = this.articleForm.get('articleTags').value;
    content = content.filter(x => x != tag);
    this.articleForm.patchValue({ articleTags: content });
  }

  removeRelatedArticle(article: RelatedArticleWithTitle) {    
    let content = this.articleForm.get('relatedArticles').value;
    content = content.filter(x => x != article);
    this.articleForm.patchValue({ relatedArticles: content });
  }

  relatedArticleDialog() {
    const dialogRef = this.dialog.open(RelatedarticleselectorComponent, {
      width: '730px', height: '800px',
      data: { sourceArticleId: this.article.id, selectedArticle: {} as Article }
    });

    dialogRef.componentInstance.articleSelected.subscribe((res) => {      
      if (res.id !== this.article.id && !this.containsRelatedArticle(res.id)) {
        //console.log('ArticleId: ' + this.article.id);
        this.relatedArticles.push({ id: 0, articleId: (this.article.id != undefined ? this.article.id : 0), relatedArticleId: res.id, title: res.title });
        //if (this.article.id == undefined){
        //  this.articleForm.get('relatedArticles').patchValue({ id: 0, articleId: 0, relatedArticleId: res.id, title: res.title });
        //}
        this.toastr.success('Hozzáadva!')
        console.log(this.relatedArticles);
      }
    });
  }

  containsRelatedArticle(Id: number) {
    return this.relatedArticles.filter(x => x.relatedArticleId == Id).length > 0;
  }

  extractImage(content: string, imgarray: ImageObject[]) {

    let image = {} as ImageObject;
    image.imgAttrStart = content.indexOf("data:image");
    
    if (image.imgAttrStart !== -1) {
      content = content.substring(image.imgAttrStart);

      let extensiontagstart = content.indexOf("/") + 1;
      let extensiontagend = content.indexOf(";");
      image.extension = content.substr(extensiontagstart, extensiontagend - extensiontagstart);
      let imageDataStart: number = content.indexOf(",") + 1;
      let imageDataEnd: number = content.indexOf(" ");
      image.imgAttrEnd = imageDataEnd + image.imgAttrStart - 1;
      let datastring = content.substr(imageDataStart, imageDataEnd - imageDataStart - 1);            
      if (imgarray.length > 0 && imgarray[imgarray.length - 1] !== undefined) {
        image.imgAttrStart += imgarray[imgarray.length - 1].imgAttrEnd;
        image.imgAttrEnd += imgarray[imgarray.length - 1].imgAttrEnd;
      }

      let imagedata = [];

      let byteCharacters = atob(datastring);
      for (var i = 0; i < byteCharacters.length; i++) {
        imagedata.push(byteCharacters.charCodeAt(i));
      }

      image.imagedata = new Blob([new Uint8Array(imagedata)], { type: 'image/' + image.extension });

      imgarray.push(image);
      this.extractImage(content.substr(imageDataEnd - 1), imgarray);
    }
  }

  public dropped(files: NgxFileDropEntry[]) {
    
    let droppedFile = files[0];    
    
    if (droppedFile.fileEntry.isFile &&
        (droppedFile.fileEntry.name.toLowerCase().indexOf('.png') !== -1 ||
         droppedFile.fileEntry.name.toLowerCase().indexOf('.bmp') !== -1 ||
         droppedFile.fileEntry.name.toLowerCase().indexOf('.jpg') !== -1 ||
         droppedFile.fileEntry.name.toLowerCase().indexOf('.jpeg') !== -1 ||
         droppedFile.fileEntry.name.toLowerCase().indexOf('.gif') !== -1)) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          var urlCreator = window.URL;

            this.imgresize.resizeImage(file, 300,
              300).subscribe((res) => {
                let newimage = res;

                let imageUrl = this.sanitazer.bypassSecurityTrustUrl(urlCreator.createObjectURL(newimage));
                this.mainPageImageSrc = imageUrl;
                this.mainPageImageData = newimage;
                this.mainPageImageDisplay = 'block';
                this.mainPageImageDropDisplay = 'none';
              }, error => { this.toastr.warning("Nem sikerült a kép felbontásának csökkentése"); });
        });
      }
  }

  public deleteIntroImage() {
    this.mainPageImageSrc = "";
    this.mainPageImageDisplay = 'none';
    this.mainPageImageDropDisplay = 'block';   
  }
  
    ngOnInit() {
        //this.configurationservice.GetConfiguration();
        this.route.paramMap.subscribe(param => {
            this.categoryservice.getAllCategories((res) => {
                this.categories = res.body;
                let id = +param.get('id');
                if (id !== 0) {
                    this.getArticle(+param.get('id'));
                }
                else {
                    this.articleForm.get('title').valueChanges.pipe(
                        switchMap((val) => { return this.articleservice.getAlias(val) })
                    ).subscribe(val =>
                    {
                        this.article.alias = val.body["alias"];
                        //console.log(val.body["alias"]);
                    });
                }
            });
        })        
  }

}
