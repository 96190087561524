import { Injectable } from '@angular/core';
import { Category } from './models/NSwagGeneratedClasses';
import { EntityWithPagination } from './models/EntityWithPagination';
import { HttpClient, HttpHeaders, HttpResponse, HttpHeaderResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, tap, concatMap } from 'rxjs/operators';
import { Text } from '@angular/compiler/src/i18n/i18n_ast';
import { AuthService } from './auth.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorhandlerService } from './errorhandler.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  private categoryUrl = 'api/Category';

  getCategories(term: string = "", pagesize: number, orderby: string, orderdirection: number, page: number, callback: Function) {
    this.authservice.GetAuthHeader().pipe(
    concatMap(val=>this.http.get<EntityWithPagination<Category>>(this.categoryUrl + "/Get" + "/?term=" + term + "&pagesize=" + pagesize + "&orderby=" + orderby + "&orderdirection=" + orderdirection + "&page=" + page, { headers: val, observe: 'response' }))).subscribe(
      res => { callback(res); },
      error => { this.errorhandler.Handle(error); }
    );
  };

  getAllCategories(callback: Function) {
    this.authservice.GetAuthHeader().pipe(
    concatMap(val=>this.http.get<Category>(this.categoryUrl + "/GetAll", { headers: val, observe: 'response' }))).subscribe(
      res => { callback(res); },
      error => { this.errorhandler.Handle(error); }
    );
  };

  delCategory(Id: number, callback: Function) {
    this.authservice.GetAuthHeader().pipe(
    concatMap(val=>this.http.delete(this.categoryUrl + "/" + Id, { headers: val, observe: 'response', responseType: 'text' }))).subscribe(
      res => { callback(res); },
      error => { this.errorhandler.Handle(error); }
    );
  }
  
  addCategory(category: Category, callback: Function) {
    this.authservice.GetAuthHeader().pipe(
    concatMap(val=>this.http.post<Category>(this.categoryUrl, category, { headers: val, observe: 'response' }))).subscribe(
      res => { callback(res); },
      error => { this.errorhandler.Handle(error); }
    );
  }
  constructor(private http: HttpClient, private authservice: AuthService, private errorhandler: ErrorhandlerService ) { }
}
