import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
  @Input()
  set numberofpages(pages: number) {
    this.NumberIterator = new Array(pages);
    this.numberofPages = pages;
  }

  numberofPages: number;

  @Input() actualpage: number;

  @Output() linkClicked = new EventEmitter<number>();

  linkclick(page: number) {
    this.linkClicked.emit(page);
  }

  private NumberIterator: number[];

  constructor() {
  }

  ngOnInit() {
    }
  }
