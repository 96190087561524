import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpHeaderResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { ErrorhandlerService } from './errorhandler.service';
import { Observable } from 'rxjs';
import { User } from './models/NSwagGeneratedClasses';
import { concatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  userUrl: string = "api/Account";

  constructor(private http: HttpClient, private authservice: AuthService, private errorhandler: ErrorhandlerService) { }

  getUsers(term: string = "", pagesize: number, orderby: string, orderdirection: number, page: number, callback: Function) {
    this.authservice.GetAuthHeader().pipe(
    concatMap(val=>this.http.get<Observable<User>>(this.userUrl + "/Get?" + term + "&pagesize=" + pagesize + "&orderby=" + orderby + "&orderdirection=" + orderdirection + "&page=" + page, { headers: val, observe: "response" })))
      .subscribe(
        res => { callback(res); },
        error => { this.errorhandler.Handle(error); })
  }

  getAvailableClaims() {

  }

  getUserClaims(Id: number) {

  }

  toggleBlockUser(Id: number, callback: Function) {
    this.authservice.GetAuthHeader().pipe(
    concatMap(val=>this.http.post(this.userUrl + "/ToggleBlockUser?Id=" + Id, null, { headers: val, observe: 'response' }))).subscribe(
      res => { callback(res); },
      error => { this.errorhandler.Handle(error); })
  }

  deleteUser(Id: number, callback: Function) {
    this.authservice.GetAuthHeader().pipe(
    concatMap(val=>this.http.post(this.userUrl + "/DeleteUser?Id=" + Id, null, { headers: val, observe: 'response' }))).subscribe(
      res => { callback(res); },
      error => { this.errorhandler.Handle(error); })
  }


}
