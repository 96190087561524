import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../models/NSwagGeneratedClasses';
import { UserService } from '../user.service';
import { PageEvent } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  constructor(private userservice: UserService, private toastr: ToastrService) { }

  users: User[];

  term: string = "";
  orderby: string = "";
  orderdirection: number = 2;
  pagesize: number = 10;
  page: number = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  numberofpages;
  numberofallitems: number;

  paginationHandler(event: PageEvent) {
    this.page = event.pageIndex;
    this.pagesize = event.pageSize;
    this.getUsers();
  }

  getUsers() {
    this.userservice.getUsers(this.term, this.pagesize, this.orderby, this.orderdirection, this.page, res => {
      this.users = res.body.results;
      this.numberofpages = Math.ceil(res.body.count / this.pagesize);
      this.numberofallitems = res.body.count; });

  }

  toggleblockUser(Id: number) {
    let user = this.users.find(x => x.id == Id);
    this.userservice.toggleBlockUser(user.id, res => {
      this.toastr.success("Sikeres mentés");
      this.getUsers();
    });
  }

  deleteUser(Id: number) {
    this.userservice.deleteUser(Id, res => {
      if (res.ok) {
        this.toastr.success("Sikeres törlés");
      }      
    });
  }

  ngOnInit() {
    this.getUsers();
  }

}
