import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ArticleService } from '../article.service';
import * as moment from 'moment';
import { PageEvent } from '@angular/material';

@Component({
  selector: 'app-articlesearchevents',
  templateUrl: './articlesearchevents.component.html',
  styleUrls: ['./articlesearchevents.component.css']
})
export class ArticlesearcheventsComponent implements OnInit {

  searchForm: FormGroup = this.fb.group({
    term: new FormControl(''),
    dateFrom: new FormControl({ value: moment().toDate(), disabled: true }),
    dateTill: new FormControl({ value: null, disabled: true }),
    groupResults: new FormControl(true),
  }
  );

  pageEvent: PageEvent;

  events: object[] = null;

  orderby: string = "";
  orderdirection: number = 2;

  pagesize: number = 10;
  page: number = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  numberofpages;
  numberofallitems: number;
  isGrouped: boolean;

  constructor(private fb: FormBuilder, private articleservice: ArticleService) { }

  search() {
    var grouped = this.searchForm.get('groupResults').value;
    this.articleservice.getArticlesSearchEvents(this.searchForm.get('term').value, this.pagesize, this.orderby,
      this.orderdirection, this.page, this.searchForm.get('groupResults').value, (this.searchForm.get('dateFrom').value != null ? moment(this.searchForm.get('dateFrom').value).format("YYYY.MM.DD") : ""),
      (this.searchForm.get('dateTill').value != null ? moment(this.searchForm.get('dateTill').value).format("YYYY.MM.DD") : ""), res => {
        this.events = res.body.results;
        this.isGrouped = grouped;
        this.numberofallitems = res.body.count;
        this.numberofpages = this.numberofallitems / this.pagesize;
        //console.log(this.events);
      });
  }

  paginationHandler(event: PageEvent) {    
    this.page = event.pageIndex;
    this.pagesize = event.pageSize;
    this.search();
  }

  toggleChanged() {    
    this.events = null;
    this.isGrouped = null;
  }

  ngOnInit() {    
  }

}
