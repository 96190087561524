import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TestResultRange, TestQuestion, TestAnswer, TestQuestionType } from '../models/NSwagGeneratedClasses';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { FormService } from '../form.service';
import { debounceTime, distinctUntilChanged, map, exhaustMap, retry } from 'rxjs/operators';

@Component({
  selector: 'app-formquestions',
  templateUrl: './formquestions.component.html',
  styleUrls: ['./formquestions.component.css']
})
export class FormquestionsComponent implements OnInit {

  @Input() Question: TestQuestion;
  @Input() QuestionTypes: TestQuestionType[];
  @Output() deleted = new EventEmitter<TestQuestion>();

  formAnswers: TestAnswer[] = [];

  QuestionForm: FormGroup;


  delete() {
    this.formservice.delQuestion(this.Question, (res) => {
      if (res.ok) {
        this.deleted.emit(this.Question);
      }
    });
  }

  removeAnswer(answer: TestAnswer) {
    this.formAnswers = this.formAnswers.filter(x => x.id != answer.id);
  }

  newAnswer() {
    this.formAnswers.push({ testQuestionId: this.Question.id, id: 0, isRightAnswer: false, ordering: 0, pointValue: 0, text: "", testQuestion: null} as TestAnswer)
  }

  constructor(private fb: FormBuilder, private formservice: FormService) { }

  ngOnInit() {
    console.log("Question is: " + this.Question.id);
    this.QuestionForm = this.fb.group({
      text: new FormControl(this.Question.text),
      ordering: new FormControl(this.Question.ordering),
      published: new FormControl(this.Question.published),
      displayRightAnswerOnNext: new FormControl(this.Question.displayRightAnswerOnNext),
      testQuestionTypeId: new FormControl(this.Question.testQuestionTypeId)
    })

    if (this.Question != null && this.Question.id > 0) {
      this.formservice.getAnswers(this.Question.id, (res) => {
        if (res.ok) {
          this.formAnswers = res.body;
        }
      })
    }

    this.QuestionForm.valueChanges.
      pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        map(res => {
          let question: TestQuestion = res;
          question.id = this.Question.id;
          question.testId = this.Question.testId;
          question.test = null;
          question.testAnswers = null;          
          return question;
        }),
        exhaustMap(res => this.formservice.saveQuestion(res)),
        retry()
      ).
      subscribe(res => {
        if (res.ok) {
          this.Question = res.body as TestQuestion;
        }
      });
  }
}
