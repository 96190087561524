import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormService } from '../form.service';
import { Test, TestCategory } from '../models/NSwagGeneratedClasses';

@Component({
  selector: 'app-formcategories',
  templateUrl: './formcategories.component.html',
  styleUrls: ['./formcategories.component.css']
})
export class FormcategoriesComponent implements OnInit {

  private TestCategories: TestCategory[];
  @Output() selectedCategoryChanged = new EventEmitter<TestCategory>();

  private selectedCategory: TestCategory;
  constructor(private formservice: FormService) { }

  changed() {
    this.selectedCategoryChanged.emit(this.selectedCategory);
  }

  ngOnInit() {
       
  }

}
