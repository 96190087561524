import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material';
import { ArticleService } from '../article.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.css']
})
export class CommentsComponent implements OnInit {

  Comments: object[];
  articleId: number = 0;
    state: number = 0;

  pagesize: number = 10;
  page: number = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  numberofpages;
  numberofallitems: number;

  constructor(private articleservice: ArticleService, private toastr: ToastrService) { }

  paginationHandler(event: PageEvent) {
      //if (page == 0 && this.page < this.numberofpages) {
      //  this.page++;
      //  this.getArticles();
      //}
      //else if (page == -1 && this.page > 1) {
      //  this.page--;
      //  this.getArticles();
      //}
      //else {
      //  this.page = page;
      //  this.getArticles();
      //}
      this.page = event.pageIndex;
      this.pagesize = event.pageSize;
      this.getComments();
  }

  getComments() {
      this.articleservice.getAllComments(this.articleId, this.state, this.pagesize, this.page, res => {
          this.Comments = res.body.results;
          this.numberofpages = Math.ceil(res.body.count / this.pagesize);
          this.numberofallitems = res.body.count;
      })
  }

    switchState(Id: number) {
        this.articleservice.SwitchState(Id, res => {
            this.toastr.success("Sikeres módosítás!");
            this.getComments();
        });
    }

    deleteComment(Id: number) {
        this.articleservice.DeleteComment(Id, res => {
            this.toastr.success("Sikeres törlés!");
            this.getComments();
        });
    }

    ngOnInit() {
        this.getComments();
  }

}
