import { Injectable } from '@angular/core';
import { concatMap, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { ErrorhandlerService } from './errorhandler.service';
import { Test, TestCategory, Category, TestQuestion, TestResultRange, TestAnswer, TestQuestionType } from './models/NSwagGeneratedClasses';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  private formUrl = 'api/Form/';

  getFormCategories(callback: Function) {
    this.authservice.GetAuthHeader().pipe(
      concatMap(val => this.http.get<TestCategory>(this.formUrl + "GetCategories", { headers: val, observe: 'response' }))).
      subscribe(res => { callback(res); },
        error => { this.errorhandler.Handle(error); })
  };

  getForms(categoryId: number, callback: Function) {
    this.authservice.GetAuthHeader().pipe(
      concatMap(val => this.http.get<Test>(this.formUrl + "GetTests/?CategoryId=" + categoryId, { headers: val, observe: 'response' }))).
      subscribe(res => { callback(res); },
        error => { this.errorhandler.Handle(error); })
  };

  getQuestions(formId: number, callback: Function) {
    this.authservice.GetAuthHeader().pipe(
      concatMap(val => this.http.get<TestQuestion>(this.formUrl + "GetQuestions/?TestId=" + formId, { headers: val, observe: 'response' }))).
      subscribe(res => { callback(res); },
        error => { this.errorhandler.Handle(error); })
  };

  getQuestionTypes(callback: Function) {
    this.authservice.GetAuthHeader().pipe(
      concatMap(val => this.http.get<TestQuestionType>(this.formUrl + "GetQuestionTypes", { headers: val, observe: 'response' }))).
      subscribe(res => { callback(res); },
        error => { this.errorhandler.Handle(error); })
  }

  getResultRanges(formId: number, callback: Function) {
    this.authservice.GetAuthHeader().pipe(
      concatMap(val => this.http.get<TestResultRange>(this.formUrl + "GetResultRanges/?TestId=" + formId, { headers: val, observe: 'response' }))).
      subscribe(res => { callback(res); },
        error => { this.errorhandler.Handle(error); })
  };

  getAnswers(questionId: number, callback: Function) {
    this.authservice.GetAuthHeader().pipe(
      concatMap(val => this.http.get<TestAnswer>(this.formUrl + "GetAnswers/?QuestionId=" + questionId, { headers: val, observe: 'response' }))).
      subscribe(res => { callback(res); },
        error => { this.errorhandler.Handle(error); })
  };

  saveResultRange(resultrange: TestResultRange, callback?: Function) {
    let observable = this.authservice.GetAuthHeader().pipe(
      concatMap(val => this.http.post<TestResultRange>(this.formUrl + "SaveResultRange", resultrange, { headers: val, observe: 'response' })));
    if (callback) {
      observable.subscribe(res => { callback(res); },
        error => { this.errorhandler.Handle(error); })
    }
    else {
      return observable;
    } 
  };

  saveQuestion(question: TestQuestion, callback?: Function) {
    let observable = this.authservice.GetAuthHeader().pipe(
      concatMap(val => this.http.post<TestQuestion>(this.formUrl + "SaveQuestion", question, { headers: val, observe: 'response' })));
    if (callback) {
      observable.subscribe(res => { callback(res); },
        error => { this.errorhandler.Handle(error); })
    }
    else {
      return observable;
    } 
  }

  saveAnswer(answer: TestAnswer, callback?: Function) {
    let observable = this.authservice.GetAuthHeader().pipe(
      concatMap(val => this.http.post<TestAnswer>(this.formUrl + "SaveAnswer", answer, { headers: val, observe: 'response' })));
    if (callback) {
      observable.subscribe(res => { callback(res); },
        error => { this.errorhandler.Handle(error); })
    }
    else {
      return observable;
    }
  }

  delResultRange(resultrange: TestResultRange, callback: Function) {
    this.authservice.GetAuthHeader().pipe(
      concatMap(val => this.http.request<TestResultRange>('DELETE', this.formUrl + "DeleteResultRange", { headers: val, observe: 'response', body: resultrange }))).
      subscribe(res=>callback(res),
        error => { this.errorhandler.Handle(error); })
  }

  delQuestion(question: TestQuestion, callback: Function) {
    this.authservice.GetAuthHeader().pipe(
      concatMap(val => this.http.request<TestQuestion>('DELETE', this.formUrl + "DeleteQuestion", { headers: val, observe: 'response', body: question }))).
      subscribe(res => callback(res),
        error => { this.errorhandler.Handle(error); })
  }

  delAnswer(answer: TestAnswer, callback: Function) {
    this.authservice.GetAuthHeader().pipe(
      concatMap(val => this.http.request<TestAnswer>('DELETE', this.formUrl + "DeleteAnswer", { headers: val, observe: 'response', body: answer }))).
      subscribe(res => callback(res),
        error => { this.errorhandler.Handle(error); })
  }

  constructor(private http: HttpClient, private authservice: AuthService, private errorhandler: ErrorhandlerService) { }
}
