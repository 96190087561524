import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-userstatus',
  templateUrl: './userstatus.component.html',
  styleUrls: ['./userstatus.component.css']
})
export class UserstatusComponent implements OnInit {
  //username(): string {
  //  return this.authservice.getCurrentUser().username;
  //}

  logout() {
    this.authservice.Logout();    
  }

  constructor(private authservice: AuthService){ }

  ngOnInit() {
  }

}
