import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { EditorModule } from '@tinymce/tinymce-angular';
import {
  MatButtonModule, MatCheckboxModule, MatDatepickerModule, MatNativeDateModule, MatFormFieldModule, MatInputModule,
  MatSelectModule, MatSlideToggleModule, MatChipsModule, MatIconModule, MatDialogModule, MatPaginatorModule, MatPaginatorIntl, MatCardModule, MAT_DATE_LOCALE } from '@angular/material';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CategoriesComponent } from './categories/categories.component';
import { PaginationComponent } from './pagination/pagination.component';
import { LoginComponent } from './login/login.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { ArticleComponent } from './article/article.component';
import { ArticleEditorComponent } from './article-editor/article-editor.component';
import { UserstatusComponent } from './userstatus/userstatus.component';
import { SimplepageComponent } from './simplepage.component';
import { TemplatepageComponent } from './templatepage.component';
import { RelatedarticleselectorComponent } from './relatedarticleselector/relatedarticleselector.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxFileDropModule  } from 'ngx-file-drop';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { PollsComponent } from './polls/polls.component';
import { DialogboolComponent } from './dialogbool/dialogbool.component';
import { PollEditorComponent } from './poll-editor/poll-editor.component';
import { UsersComponent } from './users/users.component';
import { FormsComponent } from './forms/forms.component';
import { FormcategoriesComponent } from './formcategories/formcategories.component';
import { FormlistComponent } from './formlist/formlist.component';
import { FormquestionsComponent } from './formquestions/formquestions.component';
import { FormanswersComponent } from './formanswers/formanswers.component';
import { FormresultrangeComponent } from './formresultrange/formresultrange.component';
import { CommentsComponent } from './comments/comments.component';
import { ArticlesearcheventsComponent } from './articlesearchevents/articlesearchevents.component';

export class MatPaginatorIntlHun extends MatPaginatorIntl {
  firstPageLabel: string = "Első oldal";
  itemsPerPageLabel: string = "Elemek száma oldalanként";
  lastPageLabel: string = "Utolsó oldal";
  nextPageLabel: string = "Következő oldal";
  previousPageLabel: string = "Előző oldal";
}

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CategoriesComponent,
    PaginationComponent,
    LoginComponent,
    PagenotfoundComponent,
    ArticleComponent,
    ArticleEditorComponent,
    UserstatusComponent,
    SimplepageComponent,
    TemplatepageComponent,
    RelatedarticleselectorComponent,
    PollsComponent,
    DialogboolComponent,
    PollEditorComponent,
    UsersComponent,
    FormsComponent,
    FormcategoriesComponent,
    FormlistComponent,
    FormquestionsComponent,
    FormanswersComponent,
    FormresultrangeComponent,
    CommentsComponent,
    ArticlesearcheventsComponent
  ],
  entryComponents: [RelatedarticleselectorComponent, DialogboolComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    EditorModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatIconModule,
    MatDialogModule,
    ReactiveFormsModule,
    NgxFileDropModule,
    Ng2ImgMaxModule,
    MatPaginatorModule,
    MatCardModule,
    ToastrModule.forRoot()
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'hu-HU' }, { provide: MatPaginatorIntl, useClass: MatPaginatorIntlHun }],
  bootstrap: [AppComponent]
})
export class AppModule { }
