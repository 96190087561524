import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './auth.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ErrorhandlerService {

  Handle(error: HttpErrorResponse) {
    if (error.status == 401) {
      this.authservice.Logout();
      this.toastr.error("Lejárt munkamenet!");
    }
    else {      
      if (error.error.length > 0) {
        this.toastr.error(error.error);
      }
      else {
        this.toastr.error("Hiba történt a művelet végrehajtása közben!");
      }      
    }
  }

  constructor(private toastr: ToastrService, private authservice: AuthService) { }
}
