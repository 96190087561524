import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { ErrorhandlerService } from './errorhandler.service';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  mediaUrl: string = 'api/Media'

  uploadImage(filecontent: FormData, foldername: string, filename: string, callback: Function, handler?: Function) {
      this.authservice.GetAuthHeader().pipe(
          concatMap(val => this.http.post<Observable<Response>>(this.mediaUrl, filecontent, { headers: val.append('enctype', 'multipart/form-data'), observe: 'response' })))
          .subscribe(
              res => {
                  callback(res);
              },
              error => this.errorhandler.Handle(error));
  }

  constructor(private http: HttpClient, private authservice: AuthService, private errorhandler: ErrorhandlerService) { }
}
