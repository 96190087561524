import { Component, OnInit, Input } from '@angular/core';
import { Test, TestCategory } from '../models/NSwagGeneratedClasses';
import { FormService } from '../form.service';

@Component({
  selector: 'app-formlist',
  templateUrl: './formlist.component.html',
  styleUrls: ['./formlist.component.css']
})
export class FormlistComponent implements OnInit {

  @Input() category: TestCategory;

  private Tests: Test[];
  private selectedTest: Test;

  constructor(private formservice: FormService) {

  }



  ngOnInit() {
    console.log("Formlist component created!");
    console.log(this.category);
    if (this.category !== null && this.category !== undefined) {
      this.formservice.getForms(this.category.id, (res) => {
        this.Tests = res.body;
        console.log(this.Tests);
        //console.log("Selected TestId = " + this.selectedTest);
      });
    }    
  }

}
