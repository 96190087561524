import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TestAnswer } from '../models/NSwagGeneratedClasses';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { FormService } from '../form.service';
import { debounceTime, distinctUntilChanged, map, exhaustMap, retry } from 'rxjs/operators';

@Component({
  selector: 'app-formanswers',
  templateUrl: './formanswers.component.html',
  styleUrls: ['./formanswers.component.css']
})
export class FormanswersComponent implements OnInit {

  @Input() Answer: TestAnswer;
  @Output() deleted = new EventEmitter<TestAnswer>();
  AnswerForm: FormGroup;

  delete() {
    this.formservice.delAnswer(this.Answer, (res) => {
      if (res.ok) {
        this.deleted.emit(this.Answer);
      }
    });
  }

  constructor(private fb: FormBuilder, private formservice: FormService) { }

  ngOnInit() {
    this.AnswerForm = this.fb.group({
      text: new FormControl(this.Answer.text),
      ordering: new FormControl(this.Answer.ordering),
      pointValue: new FormControl(this.Answer.pointValue),
      isRightAnswer: new FormControl(this.Answer.isRightAnswer)      
    })

    this.AnswerForm.valueChanges.
      pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        map(res => {
          let answer: TestAnswer = res;
          answer.id = this.Answer.id;
          answer.testQuestionId = this.Answer.testQuestionId;
          answer.testQuestion = null;
          return answer;
        }),
        exhaustMap(res => this.formservice.saveAnswer(res)),
        retry()
      ).
      subscribe(res => {
        if (res.ok) {
          this.Answer = res.body as TestAnswer;
        }
      });
  }

}
