import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { TestResultRange } from '../models/NSwagGeneratedClasses';
import { Observable, Subscriber, Subscription, pipe, concat, of } from 'rxjs';
import { FormService } from '../form.service';
import { switchMap, concatMap, map, debounceTime, mergeMap, exhaustMap, catchError, flatMap, retry, distinctUntilChanged } from 'rxjs/operators';


@Component({
  selector: 'app-formresultrange',
  templateUrl: './formresultrange.component.html',
  styleUrls: ['./formresultrange.component.css']
})
export class FormresultrangeComponent implements OnInit {

  @Input() resultrange: TestResultRange;
  @Output() deleted = new EventEmitter<TestResultRange>();
  resultrangeForm: FormGroup;

  delete() {
    this.formservice.delResultRange(this.resultrange, (res) => {
      if (res.ok) {
        this.deleted.emit(this.resultrange);
      }
    });
  }

  constructor(private fb: FormBuilder, private formservice: FormService) { }

  ngOnInit() {
    this.resultrangeForm = this.fb.group({
      minPoints: new FormControl(this.resultrange.minPoints),
      maxPoints: new FormControl(this.resultrange.maxPoints),
      resultText: new FormControl(this.resultrange.resultText),
    });

    this.resultrangeForm.valueChanges.
        pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          map(res => {
            let resultrange: TestResultRange = res;
            resultrange.id = this.resultrange.id;
            resultrange.testId = this.resultrange.testId;
            resultrange.test = null;
            return resultrange;
          }),
          exhaustMap(res => this.formservice.saveResultRange(res)),
          retry()
        ).
        subscribe(res => {
          if (res.ok) {
            this.resultrange = res.body as TestResultRange;
          }
        });
  }

  editorSettings() {
    return {
      skin_url: '/assets/tinymce/skins/lightgray',
      inline: false,
      statusbar: false,
      browser_spellcheck: true,
      entity_encoding: 'raw',
      height: 620,
      toolbar: ['image', 'media', 'table', 'fullscreen', 'forecolor', 'backcolor', 'code', 'bold', 'underline', 'italic', 'link'],
      plugins: ['fullscreen', 'image', 'imagetools', 'table', 'paste', 'autolink', 'textcolor', 'link', 'media', 'code', 'hr'],
      image_advtab: true,
      paste_data_images: true,
      relative_urls: true,
      allow_script_urls: true,
      convert_urls: false,
      image_class_list: [
        { title: 'None', value: '' },
        { title: 'Balra igazított', value: 'article_img_left' },
        { title: 'Jobbra igazított', value: 'article_img_right' }
      ],
      contextmenu: ['link', 'image', 'bold', 'underline', 'italic'],
      language: 'hu_HU',
      //content_css: "/my-styles.css",
      color_map: [
        "#fbcf7f", "Tinivagyok kiemelőszín",
        "000000", "Black",
        "993300", "Burnt orange",
        "333300", "Dark olive",
        "003300", "Dark green",
        "003366", "Dark azure",
        "000080", "Navy Blue",
        "333399", "Indigo",
        "333333", "Very dark gray",
        "800000", "Maroon",
        "FF6600", "Orange",
        "808000", "Olive",
        "008000", "Green",
        "008080", "Teal",
        "0000FF", "Blue",
        "666699", "Grayish blue",
        "808080", "Gray",
        "FF0000", "Red",
        "FF9900", "Amber",
        "99CC00", "Yellow green",
        "339966", "Sea green",
        "33CCCC", "Turquoise",
        "3366FF", "Royal blue",
        "800080", "Purple",
        "999999", "Medium gray",
        "FF00FF", "Magenta",
        "FFCC00", "Gold",
        "FFFF00", "Yellow",
        "00FF00", "Lime",
        "00FFFF", "Aqua",
        "00CCFF", "Sky blue",
        "993366", "Red violet",
        "FFFFFF", "White",
        "FF99CC", "Pink",
        "FFCC99", "Peach",
        "FFFF99", "Light yellow",
        "CCFFCC", "Pale green",
        "CCFFFF", "Pale cyan",
        "99CCFF", "Light sky blue",
        "CC99FF", "Plum"
      ],
      table_class_list: [
        { title: 'None', value: '' },
        { title: 'Megjegyzés box', value: 'author_message' }
      ]
    };
  }
}
