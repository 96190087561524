export class Article implements IArticle {
  id!: number;
  alias?: string | undefined;
  title?: string | undefined;
  articleAuthor?: string | undefined;
  authorAlias?: string | undefined;
  introHTML?: string | undefined;
  introImageUrl?: string | undefined;
  bodyHTML?: string | undefined;
  isDisplayedOnMainPage!: boolean;
  commentSectionVisible!: boolean;
  canComment!: boolean;
  canRate!: boolean;
  hits?: number | undefined;
  createdDate?: Date | undefined;
  publicationDate?: Date | undefined;
  publicationEndDate?: Date | undefined;
  articleCategory?: ArticleCategory[] | undefined;
  articleTags?: ArticleTag[] | undefined;
  relatedArticles?: RelatedArticle[] | undefined;
  articleRelatedArticles?: RelatedArticle[] | undefined;
  articleRatings?: ArticleRating[] | undefined;

  constructor(data?: IArticle) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.id = _data["id"];
      this.alias = _data["alias"];
      this.title = _data["title"];
      this.articleAuthor = _data["articleAuthor"];
      this.authorAlias = _data["authorAlias"];
      this.introHTML = _data["introHTML"];
      this.introImageUrl = _data["introImageUrl"];
      this.bodyHTML = _data["bodyHTML"];
      this.isDisplayedOnMainPage = _data["isDisplayedOnMainPage"];
      this.commentSectionVisible = _data["commentSectionVisible"];
      this.canComment = _data["canComment"];
      this.canRate = _data["canRate"];
      this.hits = _data["hits"];
      this.createdDate = _data["createdDate"] ? new Date(_data["createdDate"].toString()) : <any>undefined;
      this.publicationDate = _data["publicationDate"] ? new Date(_data["publicationDate"].toString()) : <any>undefined;
      this.publicationEndDate = _data["publicationEndDate"] ? new Date(_data["publicationEndDate"].toString()) : <any>undefined;
      if (Array.isArray(_data["articleCategory"])) {
        this.articleCategory = [] as any;
        for (let item of _data["articleCategory"])
          this.articleCategory!.push(ArticleCategory.fromJS(item));
      }
      if (Array.isArray(_data["articleTags"])) {
        this.articleTags = [] as any;
        for (let item of _data["articleTags"])
          this.articleTags!.push(ArticleTag.fromJS(item));
      }
      if (Array.isArray(_data["relatedArticles"])) {
        this.relatedArticles = [] as any;
        for (let item of _data["relatedArticles"])
          this.relatedArticles!.push(RelatedArticle.fromJS(item));
      }
      if (Array.isArray(_data["articleRelatedArticles"])) {
        this.articleRelatedArticles = [] as any;
        for (let item of _data["articleRelatedArticles"])
          this.articleRelatedArticles!.push(RelatedArticle.fromJS(item));
      }
      if (Array.isArray(_data["articleRatings"])) {
        this.articleRatings = [] as any;
        for (let item of _data["articleRatings"])
          this.articleRatings!.push(ArticleRating.fromJS(item));
      }
    }
  }

  static fromJS(data: any): Article {
    data = typeof data === 'object' ? data : {};
    let result = new Article();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["alias"] = this.alias;
    data["title"] = this.title;
    data["articleAuthor"] = this.articleAuthor;
    data["authorAlias"] = this.authorAlias;
    data["introHTML"] = this.introHTML;
    data["introImageUrl"] = this.introImageUrl;
    data["bodyHTML"] = this.bodyHTML;
    data["isDisplayedOnMainPage"] = this.isDisplayedOnMainPage;
    data["commentSectionVisible"] = this.commentSectionVisible;
    data["canComment"] = this.canComment;
    data["canRate"] = this.canRate;
    data["hits"] = this.hits;
    data["createdDate"] = this.createdDate ? this.createdDate.toISOString() : <any>undefined;
    data["publicationDate"] = this.publicationDate ? this.publicationDate.toISOString() : <any>undefined;
    data["publicationEndDate"] = this.publicationEndDate ? this.publicationEndDate.toISOString() : <any>undefined;
    if (Array.isArray(this.articleCategory)) {
      data["articleCategory"] = [];
      for (let item of this.articleCategory)
        data["articleCategory"].push(item.toJSON());
    }
    if (Array.isArray(this.articleTags)) {
      data["articleTags"] = [];
      for (let item of this.articleTags)
        data["articleTags"].push(item.toJSON());
    }
    if (Array.isArray(this.relatedArticles)) {
      data["relatedArticles"] = [];
      for (let item of this.relatedArticles)
        data["relatedArticles"].push(item.toJSON());
    }
    if (Array.isArray(this.articleRelatedArticles)) {
      data["articleRelatedArticles"] = [];
      for (let item of this.articleRelatedArticles)
        data["articleRelatedArticles"].push(item.toJSON());
    }
    if (Array.isArray(this.articleRatings)) {
      data["articleRatings"] = [];
      for (let item of this.articleRatings)
        data["articleRatings"].push(item.toJSON());
    }
    return data;
  }
}

export interface IArticle {
  id: number;
  alias?: string | undefined;
  title?: string | undefined;
  articleAuthor?: string | undefined;
  authorAlias?: string | undefined;
  introHTML?: string | undefined;
  introImageUrl?: string | undefined;
  bodyHTML?: string | undefined;
  isDisplayedOnMainPage: boolean;
  commentSectionVisible: boolean;
  canComment: boolean;
  canRate: boolean;
  hits?: number | undefined;
  createdDate?: Date | undefined;
  publicationDate?: Date | undefined;
  publicationEndDate?: Date | undefined;
  articleCategory?: ArticleCategory[] | undefined;
  articleTags?: ArticleTag[] | undefined;
  relatedArticles?: RelatedArticle[] | undefined;
  articleRelatedArticles?: RelatedArticle[] | undefined;
  articleRatings?: ArticleRating[] | undefined;
}

export class ArticleCategory implements IArticleCategory {
  id!: number;
  categoryId!: number;
  category?: Category | undefined;
  articleId!: number;
  article?: Article | undefined;

  constructor(data?: IArticleCategory) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.id = _data["id"];
      this.categoryId = _data["categoryId"];
      this.category = _data["category"] ? Category.fromJS(_data["category"]) : <any>undefined;
      this.articleId = _data["articleId"];
      this.article = _data["article"] ? Article.fromJS(_data["article"]) : <any>undefined;
    }
  }

  static fromJS(data: any): ArticleCategory {
    data = typeof data === 'object' ? data : {};
    let result = new ArticleCategory();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["categoryId"] = this.categoryId;
    data["category"] = this.category ? this.category.toJSON() : <any>undefined;
    data["articleId"] = this.articleId;
    data["article"] = this.article ? this.article.toJSON() : <any>undefined;
    return data;
  }
}

export interface IArticleCategory {
  id: number;
  categoryId: number;
  category?: Category | undefined;
  articleId: number;
  article?: Article | undefined;
}

export class Category implements ICategory {
  id!: number;
  alias?: string | undefined;
  name?: string | undefined;
  articles?: ArticleCategory[] | undefined;

  constructor(data?: ICategory) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.id = _data["id"];
      this.alias = _data["alias"];
      this.name = _data["name"];
      if (Array.isArray(_data["articles"])) {
        this.articles = [] as any;
        for (let item of _data["articles"])
          this.articles!.push(ArticleCategory.fromJS(item));
      }
    }
  }

  static fromJS(data: any): Category {
    data = typeof data === 'object' ? data : {};
    let result = new Category();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["alias"] = this.alias;
    data["name"] = this.name;
    if (Array.isArray(this.articles)) {
      data["articles"] = [];
      for (let item of this.articles)
        data["articles"].push(item.toJSON());
    }
    return data;
  }
}

export interface ICategory {
  id: number;
  alias?: string | undefined;
  name?: string | undefined;
  articles?: ArticleCategory[] | undefined;
}

export class ArticleTag implements IArticleTag {
  id!: number;
  tag?: string | undefined;
  articleId!: number;
  article?: Article | undefined;

  constructor(data?: IArticleTag) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.id = _data["id"];
      this.tag = _data["tag"];
      this.articleId = _data["articleId"];
      this.article = _data["article"] ? Article.fromJS(_data["article"]) : <any>undefined;
    }
  }

  static fromJS(data: any): ArticleTag {
    data = typeof data === 'object' ? data : {};
    let result = new ArticleTag();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["tag"] = this.tag;
    data["articleId"] = this.articleId;
    data["article"] = this.article ? this.article.toJSON() : <any>undefined;
    return data;
  }
}

export interface IArticleTag {
  id: number;
  tag?: string | undefined;
  articleId: number;
  article?: Article | undefined;
}

export class RelatedArticle implements IRelatedArticle {
  id!: number;
  articleId!: number;
  article?: Article | undefined;
  relatedArticleId!: number;
  relatedArticle?: Article | undefined;

  constructor(data?: IRelatedArticle) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.id = _data["id"];
      this.articleId = _data["articleId"];
      this.article = _data["article"] ? Article.fromJS(_data["article"]) : <any>undefined;
      this.relatedArticleId = _data["relatedArticleId"];
      this.relatedArticle = _data["relatedArticle"] ? Article.fromJS(_data["relatedArticle"]) : <any>undefined;
    }
  }

  static fromJS(data: any): RelatedArticle {
    data = typeof data === 'object' ? data : {};
    let result = new RelatedArticle();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["articleId"] = this.articleId;
    data["article"] = this.article ? this.article.toJSON() : <any>undefined;
    data["relatedArticleId"] = this.relatedArticleId;
    data["relatedArticle"] = this.relatedArticle ? this.relatedArticle.toJSON() : <any>undefined;
    return data;
  }
}

export interface IRelatedArticle {
  id: number;
  articleId: number;
  article?: Article | undefined;
  relatedArticleId: number;
  relatedArticle?: Article | undefined;
}

export class ArticleRating implements IArticleRating {
  id!: number;
  ip?: string | undefined;
  username?: string | undefined;
  rating!: number;
  timestamp?: Date | undefined;
  guid!: string;
  articleId!: number;
  article?: Article | undefined;

  constructor(data?: IArticleRating) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.id = _data["id"];
      this.ip = _data["ip"];
      this.username = _data["username"];
      this.rating = _data["rating"];
      this.timestamp = _data["timestamp"] ? new Date(_data["timestamp"].toString()) : <any>undefined;
      this.guid = _data["guid"];
      this.articleId = _data["articleId"];
      this.article = _data["article"] ? Article.fromJS(_data["article"]) : <any>undefined;
    }
  }

  static fromJS(data: any): ArticleRating {
    data = typeof data === 'object' ? data : {};
    let result = new ArticleRating();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["ip"] = this.ip;
    data["username"] = this.username;
    data["rating"] = this.rating;
    data["timestamp"] = this.timestamp ? this.timestamp.toISOString() : <any>undefined;
    data["guid"] = this.guid;
    data["articleId"] = this.articleId;
    data["article"] = this.article ? this.article.toJSON() : <any>undefined;
    return data;
  }
}

export interface IArticleRating {
  id: number;
  ip?: string | undefined;
  username?: string | undefined;
  rating: number;
  timestamp?: Date | undefined;
  guid: string;
  articleId: number;
  article?: Article | undefined;
}

export class ArticleRating2 implements IArticleRating2 {
  articleId!: number;
  rating!: number;
  guid?: string | undefined;

  constructor(data?: IArticleRating2) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.articleId = _data["articleId"];
      this.rating = _data["rating"];
      this.guid = _data["guid"];
    }
  }

  static fromJS(data: any): ArticleRating2 {
    data = typeof data === 'object' ? data : {};
    let result = new ArticleRating2();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["articleId"] = this.articleId;
    data["rating"] = this.rating;
    data["guid"] = this.guid;
    return data;
  }
}

export interface IArticleRating2 {
  articleId: number;
  rating: number;
  guid?: string | undefined;
}

export class NewComment implements INewComment {
  comment?: string | undefined;
  articleId!: number;
  username?: string | undefined;

  constructor(data?: INewComment) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.comment = _data["comment"];
      this.articleId = _data["articleId"];
      this.username = _data["username"];
    }
  }

  static fromJS(data: any): NewComment {
    data = typeof data === 'object' ? data : {};
    let result = new NewComment();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["comment"] = this.comment;
    data["articleId"] = this.articleId;
    data["username"] = this.username;
    return data;
  }
}

export interface INewComment {
  comment?: string | undefined;
  articleId: number;
  username?: string | undefined;
}

export class Test implements ITest {
  id!: number;
  testCategoryId!: number;
  testCategory?: TestCategory | undefined;
  name?: string | undefined;
  intro?: string | undefined;
  published!: boolean;
  testResultRanges?: TestResultRange[] | undefined;
  testQuestions?: TestQuestion[] | undefined;

  constructor(data?: ITest) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.id = _data["id"];
      this.testCategoryId = _data["testCategoryId"];
      this.testCategory = _data["testCategory"] ? TestCategory.fromJS(_data["testCategory"]) : <any>undefined;
      this.name = _data["name"];
      this.intro = _data["intro"];
      this.published = _data["published"];
      if (Array.isArray(_data["testResultRanges"])) {
        this.testResultRanges = [] as any;
        for (let item of _data["testResultRanges"])
          this.testResultRanges!.push(TestResultRange.fromJS(item));
      }
      if (Array.isArray(_data["testQuestions"])) {
        this.testQuestions = [] as any;
        for (let item of _data["testQuestions"])
          this.testQuestions!.push(TestQuestion.fromJS(item));
      }
    }
  }

  static fromJS(data: any): Test {
    data = typeof data === 'object' ? data : {};
    let result = new Test();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["testCategoryId"] = this.testCategoryId;
    data["testCategory"] = this.testCategory ? this.testCategory.toJSON() : <any>undefined;
    data["name"] = this.name;
    data["intro"] = this.intro;
    data["published"] = this.published;
    if (Array.isArray(this.testResultRanges)) {
      data["testResultRanges"] = [];
      for (let item of this.testResultRanges)
        data["testResultRanges"].push(item.toJSON());
    }
    if (Array.isArray(this.testQuestions)) {
      data["testQuestions"] = [];
      for (let item of this.testQuestions)
        data["testQuestions"].push(item.toJSON());
    }
    return data;
  }
}

export interface ITest {
  id: number;
  testCategoryId: number;
  testCategory?: TestCategory | undefined;
  name?: string | undefined;
  intro?: string | undefined;
  published: boolean;
  testResultRanges?: TestResultRange[] | undefined;
  testQuestions?: TestQuestion[] | undefined;
}

export class TestCategory implements ITestCategory {
  id!: number;
  name?: string | undefined;
  intro?: string | undefined;
  tests?: Test[] | undefined;
  published!: boolean;

  constructor(data?: ITestCategory) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.id = _data["id"];
      this.name = _data["name"];
      this.intro = _data["intro"];
      if (Array.isArray(_data["tests"])) {
        this.tests = [] as any;
        for (let item of _data["tests"])
          this.tests!.push(Test.fromJS(item));
      }
      this.published = _data["published"];
    }
  }

  static fromJS(data: any): TestCategory {
    data = typeof data === 'object' ? data : {};
    let result = new TestCategory();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["name"] = this.name;
    data["intro"] = this.intro;
    if (Array.isArray(this.tests)) {
      data["tests"] = [];
      for (let item of this.tests)
        data["tests"].push(item.toJSON());
    }
    data["published"] = this.published;
    return data;
  }
}

export interface ITestCategory {
  id: number;
  name?: string | undefined;
  intro?: string | undefined;
  tests?: Test[] | undefined;
  published: boolean;
}

export class TestResultRange implements ITestResultRange {
  id!: number;
  minPoints!: number;
  maxPoints!: number;
  resultText?: string | undefined;
  testId!: number;
  test?: Test | undefined;

  constructor(data?: ITestResultRange) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.id = _data["id"];
      this.minPoints = _data["minPoints"];
      this.maxPoints = _data["maxPoints"];
      this.resultText = _data["resultText"];
      this.testId = _data["testId"];
      this.test = _data["test"] ? Test.fromJS(_data["test"]) : <any>undefined;
    }
  }

  static fromJS(data: any): TestResultRange {
    data = typeof data === 'object' ? data : {};
    let result = new TestResultRange();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["minPoints"] = this.minPoints;
    data["maxPoints"] = this.maxPoints;
    data["resultText"] = this.resultText;
    data["testId"] = this.testId;
    data["test"] = this.test ? this.test.toJSON() : <any>undefined;
    return data;
  }
}

export interface ITestResultRange {
  id: number;
  minPoints: number;
  maxPoints: number;
  resultText?: string | undefined;
  testId: number;
  test?: Test | undefined;
}

export class TestQuestion implements ITestQuestion {
  id!: number;
  testId!: number;
  test?: Test | undefined;
  text?: string | undefined;
  ordering!: number;
  published!: boolean;
  testAnswers?: TestAnswer[] | undefined;
  testQuestionTypeId!: number;
  testQuestionType?: TestQuestionType | undefined;
  displayRightAnswerOnNext!: boolean;

  constructor(data?: ITestQuestion) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.id = _data["id"];
      this.testId = _data["testId"];
      this.test = _data["test"] ? Test.fromJS(_data["test"]) : <any>undefined;
      this.text = _data["text"];
      this.ordering = _data["ordering"];
      this.published = _data["published"];
      if (Array.isArray(_data["testAnswers"])) {
        this.testAnswers = [] as any;
        for (let item of _data["testAnswers"])
          this.testAnswers!.push(TestAnswer.fromJS(item));
      }
      this.testQuestionTypeId = _data["testQuestionTypeId"];
      this.testQuestionType = _data["testQuestionType"] ? TestQuestionType.fromJS(_data["testQuestionType"]) : <any>undefined;
      this.displayRightAnswerOnNext = _data["displayRightAnswerOnNext"];
    }
  }

  static fromJS(data: any): TestQuestion {
    data = typeof data === 'object' ? data : {};
    let result = new TestQuestion();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["testId"] = this.testId;
    data["test"] = this.test ? this.test.toJSON() : <any>undefined;
    data["text"] = this.text;
    data["ordering"] = this.ordering;
    data["published"] = this.published;
    if (Array.isArray(this.testAnswers)) {
      data["testAnswers"] = [];
      for (let item of this.testAnswers)
        data["testAnswers"].push(item.toJSON());
    }
    data["testQuestionTypeId"] = this.testQuestionTypeId;
    data["testQuestionType"] = this.testQuestionType ? this.testQuestionType.toJSON() : <any>undefined;
    data["displayRightAnswerOnNext"] = this.displayRightAnswerOnNext;
    return data;
  }
}

export interface ITestQuestion {
  id: number;
  testId: number;
  test?: Test | undefined;
  text?: string | undefined;
  ordering: number;
  published: boolean;
  testAnswers?: TestAnswer[] | undefined;
  testQuestionTypeId: number;
  testQuestionType?: TestQuestionType | undefined;
  displayRightAnswerOnNext: boolean;
}

export class TestAnswer implements ITestAnswer {
  id!: number;
  testQuestionId!: number;
  testQuestion?: TestQuestion | undefined;
  text?: string | undefined;
  ordering!: number;
  isRightAnswer!: boolean;
  pointValue!: number;

  constructor(data?: ITestAnswer) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.id = _data["id"];
      this.testQuestionId = _data["testQuestionId"];
      this.testQuestion = _data["testQuestion"] ? TestQuestion.fromJS(_data["testQuestion"]) : <any>undefined;
      this.text = _data["text"];
      this.ordering = _data["ordering"];
      this.isRightAnswer = _data["isRightAnswer"];
      this.pointValue = _data["pointValue"];
    }
  }

  static fromJS(data: any): TestAnswer {
    data = typeof data === 'object' ? data : {};
    let result = new TestAnswer();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["testQuestionId"] = this.testQuestionId;
    data["testQuestion"] = this.testQuestion ? this.testQuestion.toJSON() : <any>undefined;
    data["text"] = this.text;
    data["ordering"] = this.ordering;
    data["isRightAnswer"] = this.isRightAnswer;
    data["pointValue"] = this.pointValue;
    return data;
  }
}

export interface ITestAnswer {
  id: number;
  testQuestionId: number;
  testQuestion?: TestQuestion | undefined;
  text?: string | undefined;
  ordering: number;
  isRightAnswer: boolean;
  pointValue: number;
}

export class TestQuestionType implements ITestQuestionType {
  id!: number;
  description?: string | undefined;

  constructor(data?: ITestQuestionType) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.id = _data["id"];
      this.description = _data["description"];
    }
  }

  static fromJS(data: any): TestQuestionType {
    data = typeof data === 'object' ? data : {};
    let result = new TestQuestionType();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["description"] = this.description;
    return data;
  }
}

export interface ITestQuestionType {
  id: number;
  description?: string | undefined;
}

export class TestPassedQuestion implements ITestPassedQuestion {
  id!: number;
  testQuestionId!: number;
  testQuestion?: TestQuestion | undefined;
  testAnswerId!: number;
  testAnswer?: TestAnswer | undefined;
  testPassedTestId!: number;
  testPassedTest?: TestPassedTest | undefined;

  constructor(data?: ITestPassedQuestion) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.id = _data["id"];
      this.testQuestionId = _data["testQuestionId"];
      this.testQuestion = _data["testQuestion"] ? TestQuestion.fromJS(_data["testQuestion"]) : <any>undefined;
      this.testAnswerId = _data["testAnswerId"];
      this.testAnswer = _data["testAnswer"] ? TestAnswer.fromJS(_data["testAnswer"]) : <any>undefined;
      this.testPassedTestId = _data["testPassedTestId"];
      this.testPassedTest = _data["testPassedTest"] ? TestPassedTest.fromJS(_data["testPassedTest"]) : <any>undefined;
    }
  }

  static fromJS(data: any): TestPassedQuestion {
    data = typeof data === 'object' ? data : {};
    let result = new TestPassedQuestion();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["testQuestionId"] = this.testQuestionId;
    data["testQuestion"] = this.testQuestion ? this.testQuestion.toJSON() : <any>undefined;
    data["testAnswerId"] = this.testAnswerId;
    data["testAnswer"] = this.testAnswer ? this.testAnswer.toJSON() : <any>undefined;
    data["testPassedTestId"] = this.testPassedTestId;
    data["testPassedTest"] = this.testPassedTest ? this.testPassedTest.toJSON() : <any>undefined;
    return data;
  }
}

export interface ITestPassedQuestion {
  id: number;
  testQuestionId: number;
  testQuestion?: TestQuestion | undefined;
  testAnswerId: number;
  testAnswer?: TestAnswer | undefined;
  testPassedTestId: number;
  testPassedTest?: TestPassedTest | undefined;
}

export class TestPassedTest implements ITestPassedTest {
  id!: number;
  start?: Date | undefined;
  end?: Date | undefined;
  userId?: number | undefined;
  user?: User | undefined;
  testId!: number;
  test?: Test | undefined;
  testPassedQuestions?: TestPassedQuestion[] | undefined;

  constructor(data?: ITestPassedTest) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.id = _data["id"];
      this.start = _data["start"] ? new Date(_data["start"].toString()) : <any>undefined;
      this.end = _data["end"] ? new Date(_data["end"].toString()) : <any>undefined;
      this.userId = _data["userId"];
      this.user = _data["user"] ? User.fromJS(_data["user"]) : <any>undefined;
      this.testId = _data["testId"];
      this.test = _data["test"] ? Test.fromJS(_data["test"]) : <any>undefined;
      if (Array.isArray(_data["testPassedQuestions"])) {
        this.testPassedQuestions = [] as any;
        for (let item of _data["testPassedQuestions"])
          this.testPassedQuestions!.push(TestPassedQuestion.fromJS(item));
      }
    }
  }

  static fromJS(data: any): TestPassedTest {
    data = typeof data === 'object' ? data : {};
    let result = new TestPassedTest();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["start"] = this.start ? this.start.toISOString() : <any>undefined;
    data["end"] = this.end ? this.end.toISOString() : <any>undefined;
    data["userId"] = this.userId;
    data["user"] = this.user ? this.user.toJSON() : <any>undefined;
    data["testId"] = this.testId;
    data["test"] = this.test ? this.test.toJSON() : <any>undefined;
    if (Array.isArray(this.testPassedQuestions)) {
      data["testPassedQuestions"] = [];
      for (let item of this.testPassedQuestions)
        data["testPassedQuestions"].push(item.toJSON());
    }
    return data;
  }
}

export interface ITestPassedTest {
  id: number;
  start?: Date | undefined;
  end?: Date | undefined;
  userId?: number | undefined;
  user?: User | undefined;
  testId: number;
  test?: Test | undefined;
  testPassedQuestions?: TestPassedQuestion[] | undefined;
}

export class User implements IUser {
  id!: number;
  userName?: string | undefined;
  name?: string | undefined;
  email?: string | undefined;
  password?: string | undefined;
  claims?: UserClaim[] | undefined;
  blocked?: boolean | undefined;
  registerDate?: Date | undefined;
  lastVisitDate?: Date | undefined;
  activatorToken?: string | undefined;
  activatorTokenValidity?: Date | undefined;
  changePasswordToken?: string | undefined;
  changePasswordTokenValidity?: Date | undefined;
  isActivated?: boolean | undefined;

  constructor(data?: IUser) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.id = _data["id"];
      this.userName = _data["userName"];
      this.name = _data["name"];
      this.email = _data["email"];
      this.password = _data["password"];
      if (Array.isArray(_data["claims"])) {
        this.claims = [] as any;
        for (let item of _data["claims"])
          this.claims!.push(UserClaim.fromJS(item));
      }
      this.blocked = _data["blocked"];
      this.registerDate = _data["registerDate"] ? new Date(_data["registerDate"].toString()) : <any>undefined;
      this.lastVisitDate = _data["lastVisitDate"] ? new Date(_data["lastVisitDate"].toString()) : <any>undefined;
      this.activatorToken = _data["activatorToken"];
      this.activatorTokenValidity = _data["activatorTokenValidity"] ? new Date(_data["activatorTokenValidity"].toString()) : <any>undefined;
      this.changePasswordToken = _data["changePasswordToken"];
      this.changePasswordTokenValidity = _data["changePasswordTokenValidity"] ? new Date(_data["changePasswordTokenValidity"].toString()) : <any>undefined;
      this.isActivated = _data["isActivated"];
    }
  }

  static fromJS(data: any): User {
    data = typeof data === 'object' ? data : {};
    let result = new User();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["userName"] = this.userName;
    data["name"] = this.name;
    data["email"] = this.email;
    data["password"] = this.password;
    if (Array.isArray(this.claims)) {
      data["claims"] = [];
      for (let item of this.claims)
        data["claims"].push(item.toJSON());
    }
    data["blocked"] = this.blocked;
    data["registerDate"] = this.registerDate ? this.registerDate.toISOString() : <any>undefined;
    data["lastVisitDate"] = this.lastVisitDate ? this.lastVisitDate.toISOString() : <any>undefined;
    data["activatorToken"] = this.activatorToken;
    data["activatorTokenValidity"] = this.activatorTokenValidity ? this.activatorTokenValidity.toISOString() : <any>undefined;
    data["changePasswordToken"] = this.changePasswordToken;
    data["changePasswordTokenValidity"] = this.changePasswordTokenValidity ? this.changePasswordTokenValidity.toISOString() : <any>undefined;
    data["isActivated"] = this.isActivated;
    return data;
  }
}

export interface IUser {
  id: number;
  userName?: string | undefined;
  name?: string | undefined;
  email?: string | undefined;
  password?: string | undefined;
  claims?: UserClaim[] | undefined;
  blocked?: boolean | undefined;
  registerDate?: Date | undefined;
  lastVisitDate?: Date | undefined;
  activatorToken?: string | undefined;
  activatorTokenValidity?: Date | undefined;
  changePasswordToken?: string | undefined;
  changePasswordTokenValidity?: Date | undefined;
  isActivated?: boolean | undefined;
}

export class UserClaim implements IUserClaim {
  id!: number;
  name?: string | undefined;
  value?: string | undefined;
  user?: User | undefined;

  constructor(data?: IUserClaim) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.id = _data["id"];
      this.name = _data["name"];
      this.value = _data["value"];
      this.user = _data["user"] ? User.fromJS(_data["user"]) : <any>undefined;
    }
  }

  static fromJS(data: any): UserClaim {
    data = typeof data === 'object' ? data : {};
    let result = new UserClaim();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["name"] = this.name;
    data["value"] = this.value;
    data["user"] = this.user ? this.user.toJSON() : <any>undefined;
    return data;
  }
}

export interface IUserClaim {
  id: number;
  name?: string | undefined;
  value?: string | undefined;
  user?: User | undefined;
}

export class Poll implements IPoll {
  id!: number;
  title?: string | undefined;
  published!: boolean;
  created?: Date | undefined;
  pollOptions?: PollOption[] | undefined;
  customUrl?: string | undefined;
  customUrlText?: string | undefined;

  constructor(data?: IPoll) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.id = _data["id"];
      this.title = _data["title"];
      this.published = _data["published"];
      this.created = _data["created"] ? new Date(_data["created"].toString()) : <any>undefined;
      if (Array.isArray(_data["pollOptions"])) {
        this.pollOptions = [] as any;
        for (let item of _data["pollOptions"])
          this.pollOptions!.push(PollOption.fromJS(item));
      }
      this.customUrl = _data["customUrl"];
      this.customUrlText = _data["customUrlText"];
    }
  }

  static fromJS(data: any): Poll {
    data = typeof data === 'object' ? data : {};
    let result = new Poll();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["title"] = this.title;
    data["published"] = this.published;
    data["created"] = this.created ? this.created.toISOString() : <any>undefined;
    if (Array.isArray(this.pollOptions)) {
      data["pollOptions"] = [];
      for (let item of this.pollOptions)
        data["pollOptions"].push(item.toJSON());
    }
    data["customUrl"] = this.customUrl;
    data["customUrlText"] = this.customUrlText;
    return data;
  }
}

export interface IPoll {
  id: number;
  title?: string | undefined;
  published: boolean;
  created?: Date | undefined;
  pollOptions?: PollOption[] | undefined;
}

export class PollWithVoteCount extends Poll implements IPollWithVoteCount {
  voteCount?: number | undefined;

  constructor(data?: IPollWithVoteCount) {
    super(data);
  }

  init(_data?: any) {
    super.init(_data);
    if (_data) {
      this.voteCount = _data["voteCount"];
    }
  }

  static fromJS(data: any): PollWithVoteCount {
    data = typeof data === 'object' ? data : {};
    let result = new PollWithVoteCount();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["voteCount"] = this.voteCount;
    super.toJSON(data);
    return data;
  }
}

export interface IPollWithVoteCount extends IPoll {
  voteCount?: number | undefined;
}

export class PollOption implements IPollOption {
  id!: number;
  pollId!: number;
  poll?: Poll | undefined;
  text?: string | undefined;
  pollVotes?: PollVote[] | undefined;
  voteCount?: number | undefined;

  constructor(data?: IPollOption) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.id = _data["id"];
      this.pollId = _data["pollId"];
      this.poll = _data["poll"] ? Poll.fromJS(_data["poll"]) : <any>undefined;
      this.text = _data["text"];
      if (Array.isArray(_data["pollVotes"])) {
        this.pollVotes = [] as any;
        for (let item of _data["pollVotes"])
          this.pollVotes!.push(PollVote.fromJS(item));
      }
      this.voteCount = _data["voteCount"];
    }
  }

  static fromJS(data: any): PollOption {
    data = typeof data === 'object' ? data : {};
    let result = new PollOption();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["pollId"] = this.pollId;
    data["poll"] = this.poll ? this.poll.toJSON() : <any>undefined;
    data["text"] = this.text;
    if (Array.isArray(this.pollVotes)) {
      data["pollVotes"] = [];
      for (let item of this.pollVotes)
        data["pollVotes"].push(item.toJSON());
    }
    data["voteCount"] = this.voteCount;
    return data;
  }
}

export interface IPollOption {
  id: number;
  pollId: number;
  poll?: Poll | undefined;
  text?: string | undefined;
  pollVotes?: PollVote[] | undefined;
  voteCount?: number | undefined;
}

export class PollVote implements IPollVote {
  id!: number;
  pollOptionId!: number;
  pollOption?: PollOption | undefined;
  date!: Date;
  ip?: string | undefined;

  constructor(data?: IPollVote) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.id = _data["id"];
      this.pollOptionId = _data["pollOptionId"];
      this.pollOption = _data["pollOption"] ? PollOption.fromJS(_data["pollOption"]) : <any>undefined;
      this.date = _data["date"] ? new Date(_data["date"].toString()) : <any>undefined;
      this.ip = _data["ip"];
    }
  }

  static fromJS(data: any): PollVote {
    data = typeof data === 'object' ? data : {};
    let result = new PollVote();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["pollOptionId"] = this.pollOptionId;
    data["pollOption"] = this.pollOption ? this.pollOption.toJSON() : <any>undefined;
    data["date"] = this.date ? this.date.toISOString() : <any>undefined;
    data["ip"] = this.ip;
    return data;
  }
}

export interface IPollVote {
  id: number;
  pollOptionId: number;
  pollOption?: PollOption | undefined;
  date: Date;
  ip?: string | undefined;
}

export class PollVote2 implements IPollVote2 {
  pollId!: number;
  optionId!: number;

  constructor(data?: IPollVote2) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.pollId = _data["pollId"];
      this.optionId = _data["optionId"];
    }
  }

  static fromJS(data: any): PollVote2 {
    data = typeof data === 'object' ? data : {};
    let result = new PollVote2();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["pollId"] = this.pollId;
    data["optionId"] = this.optionId;
    return data;
  }
}

export interface IPollVote2 {
  pollId: number;
  optionId: number;
}

export interface FileParameter {
  data: any;
  fileName: string;
}

export interface FileResponse {
  data: Blob;
  status: number;
  fileName?: string;
  headers?: { [name: string]: any };
}

export class ApiException extends Error {
  message: string;
  status: number;
  response: string;
  headers: { [key: string]: any; };
  result: any;

  constructor(message: string, status: number, response: string, headers: { [key: string]: any; }, result: any) {
    super();

    this.message = message;
    this.status = status;
    this.response = response;
    this.headers = headers;
    this.result = result;
  }

  protected isApiException = true;

  static isApiException(obj: any): obj is ApiException {
    return obj.isApiException === true;
  }
}

function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): any {
  if (result !== null && result !== undefined)
    throw result;
  else
    throw new ApiException(message, status, response, headers, null);
}
