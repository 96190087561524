import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { ErrorhandlerService } from './errorhandler.service';
import { EntityWithPagination } from './models/EntityWithPagination';
import { Poll, PollWithVoteCount } from './models/NSwagGeneratedClasses';
import { concatMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PollService {
  private pollUrl = 'api/Poll/';    

  getPolls(term: string = "", pagesize: number, orderby: string, orderdirection: number, page: number, callback: Function) {
    this.authservice.GetAuthHeader().pipe(      
      concatMap(val => this.http.get<EntityWithPagination<PollWithVoteCount>>(this.pollUrl + "Get/?term=" + term + "&pagesize=" + pagesize + "&orderby=" + orderby + "&orderdirection=" + orderdirection + "&page=" + page, { headers: val, observe: 'response' }))).
      subscribe(res => { callback(res); },
        error => { this.errorhandler.Handle(error); })    
  };

  getPoll(Id: number, callback: Function) {
    this.authservice.GetAuthHeader().pipe(
    concatMap(val=>this.http.get<PollWithVoteCount>(this.pollUrl + "GetById/?id=" + Id, { headers: val, observe: 'response' })))
      .subscribe(res => { callback(res); },
        error => this.errorhandler.Handle(error));
  }

  savePoll(Poll: PollWithVoteCount, callback: Function) {
    this.authservice.GetAuthHeader().pipe(
    concatMap(val=>this.http.post(this.pollUrl + "SavePoll", Poll, { headers: val, observe: 'response' }))).subscribe(
      res => { callback(res); },
      error => { this.errorhandler.Handle(error); })
  }
  constructor(private http: HttpClient, private authservice: AuthService, private errorhandler: ErrorhandlerService) { }
}
