import { Component, OnInit, Inject, EventEmitter, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export class DialogData {
  text: string;
  id: number;
  result: boolean;
  truetext: string = "Rendben";
  falsetext: string = "Mégsem";
}

@Component({
  selector: 'app-dialogbool',
  templateUrl: './dialogbool.component.html',
  styleUrls: ['./dialogbool.component.css']
})

export class DialogboolComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogboolComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  @Output() answerSelected = new EventEmitter<DialogData>();

  SetValueAndClose(value: boolean) {
    this.answerSelected.emit({ result: value, id: this.data.id, text: "", truetext: "", falsetext: "" });
  }

  ngOnInit() {
  }

}
