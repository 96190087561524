import { Component, OnInit } from '@angular/core';
import { Article, Category } from '../models/NSwagGeneratedClasses';
import { EntityWithPagination } from '../models/EntityWithPagination';
import { ArticleService } from '../article.service';
import { PaginationComponent } from '../pagination/pagination.component';
import { Router } from '@angular/router';
import { PageEvent } from '@angular/material';
import { CategoryService } from '../category.service';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';


@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.css']
})
export class ArticleComponent implements OnInit {

  

  constructor(private articleservice: ArticleService, private router: Router,private categoryservice: CategoryService, private fb: FormBuilder) { }

  searchForm: FormGroup = this.fb.group({
    term: new FormControl(),
    category: new FormControl(),
    createdDateFrom: new FormControl(),
    createdDateTill: new FormControl(),
    publicationDateFrom: new FormControl(),
    publicationDateTill: new FormControl(),
    author: new FormControl()
  })

  articleEditorUrl: string = "articles/";

  search() {
    console.log(this.searchForm.value);
    this.page = 0;
    this.getArticles()
  }

  pageEvent: PageEvent;

  articles: object[];
  //term: string = "";
  //createdDateFrom: Date;
  //createdDateTill: Date;
  //publicationDateFrom: Date;
  //publicationDateTill: Date;
  orderby: string = "";
  orderdirection: number = 2;

  pagesize: number = 10;
  page: number = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  numberofpages;
  numberofallitems: number;
  seachfinished: boolean = false;

  categories: Category[];
  //selectedCategories: number[] = [];


  openArticleForEdit(id: number) {
    this.router.navigateByUrl(this.articleEditorUrl + id);
  }

  deleteArticle(id: number) {

  }

  clearSearch() {
    this.searchForm.reset();
    this.getArticles();
  }

  getArticles() {
    this.seachfinished = false;
    this.articleservice.getArticles(this.searchForm.get('term').value, this.pagesize, this.orderby, this.orderdirection, this.searchForm.get('category').value,
      this.searchForm.get('createdDateFrom').value, this.searchForm.get('createdDateTill').value,
      this.searchForm.get('publicationDateFrom').value, this.searchForm.get('publicationDateTill').value,
      this.searchForm.get('author').value, this.page, res => {
      if (res.ok) {
        //console.log("GetArticles");
        //console.log(this.selectedCategories);
        this.articles = res.body.results;
        this.numberofpages = Math.ceil(res.body.count / this.pagesize);
        this.numberofallitems = res.body.count;
        this.seachfinished = true;
        //console.log(this.articles);
        this.saveState();
      }
    });
  }

  paginationHandler(event: PageEvent) {
    //if (page == 0 && this.page < this.numberofpages) {
    //  this.page++;
    //  this.getArticles();
    //}
    //else if (page == -1 && this.page > 1) {
    //  this.page--;
    //  this.getArticles();
    //}
    //else {
    //  this.page = page;
    //  this.getArticles();
    //}
    this.page = event.pageIndex;
    this.pagesize = event.pageSize;
    this.getArticles();
  }

  //setPageSizeOptions(setPageSizeOptionsInput: string) {
  //  this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  //}

  saveState() {
    localStorage.setItem('term', JSON.stringify(this.searchForm.get('term').value));
    localStorage.setItem('category', JSON.stringify(this.searchForm.get('category').value));
    localStorage.setItem('createdDateFrom', JSON.stringify(this.searchForm.get('createdDateFrom').value));
    localStorage.setItem('createdDateTill', JSON.stringify(this.searchForm.get('createdDateTill').value));
    localStorage.setItem('publicationDateFrom', JSON.stringify(this.searchForm.get('publicationDateFrom').value));
    localStorage.setItem('publicationDateTill', JSON.stringify(this.searchForm.get('publicationDateTill').value));
    localStorage.setItem('author', JSON.stringify(this.searchForm.get('author').value));
    localStorage.setItem('pagesize', JSON.stringify(this.pagesize));    
    localStorage.setItem('page', JSON.stringify(this.page));
    localStorage.setItem('numberofpages', JSON.stringify(this.numberofpages));
    localStorage.setItem('numberofallitems', JSON.stringify(this.numberofallitems));    
  }

  loadState() {
    this.searchForm.patchValue({ term: JSON.parse(localStorage.getItem('term')) ? JSON.parse(localStorage.getItem('term')) : '' });
    this.searchForm.patchValue({ category: JSON.parse(localStorage.getItem('category')) ? JSON.parse(localStorage.getItem('category')) : '' });
    this.searchForm.patchValue({ createdDateFrom: JSON.parse(localStorage.getItem('createdDateFrom')) ? JSON.parse(localStorage.getItem('createdDateFrom')) : '' });
    this.searchForm.patchValue({ createdDateTill: JSON.parse(localStorage.getItem('createdDateTill')) ? JSON.parse(localStorage.getItem('createdDateTill')) : '' });
    this.searchForm.patchValue({ publicationDateFrom: JSON.parse(localStorage.getItem('publicationDateFrom')) ? JSON.parse(localStorage.getItem('publicationDateFrom')) : '' });
    this.searchForm.patchValue({ publicationDateTill: JSON.parse(localStorage.getItem('publicationDateTill')) ? JSON.parse(localStorage.getItem('publicationDateTill')) : '' });
    this.searchForm.patchValue({ author: JSON.parse(localStorage.getItem('author')) ? JSON.parse(localStorage.getItem('author')) : '' });
    //this.fb.group({
    //  term: new FormControl(JSON.parse(localStorage.getItem('term')) ? JSON.parse(localStorage.getItem('term')) : null),
    //  category: new FormControl(JSON.parse(localStorage.getItem('category')) ? JSON.parse(localStorage.getItem('category')) : null),
    //  createdDateFrom: new FormControl(JSON.parse(localStorage.getItem('createdDateFrom')) ? JSON.parse(localStorage.getItem('createdDateFrom')) : null),
    //  createdDateTill: new FormControl(JSON.parse(localStorage.getItem('createdDateTill')) ? JSON.parse(localStorage.getItem('createdDateTill')) : null),
    //  publicationDateFrom: new FormControl(JSON.parse(localStorage.getItem('publicationDateFrom')) ? JSON.parse(localStorage.getItem('publicationDateFrom')) : null),
    //  publicationDateTill: new FormControl(JSON.parse(localStorage.getItem('publicationDateTill')) ? JSON.parse(localStorage.getItem('publicationDateTill')) : null),
    //  author: new FormControl(JSON.parse(localStorage.getItem('author')) ? JSON.parse(localStorage.getItem('author')) : null)
    //})
    

    let pagesize = JSON.parse(localStorage.getItem('pagesize'))
    if (pagesize) {
      this.pagesize = pagesize;
    }

    let page = JSON.parse(localStorage.getItem('page'))
    if (page) {
      this.page = page;
    }

    let numberofpages = JSON.parse(localStorage.getItem('numberofpages'))
    if (numberofpages) {
      this.numberofpages = numberofpages;
    }

    let numberofallitems = JSON.parse(localStorage.getItem('numberofallitems'))
    if (numberofallitems) {
      this.numberofallitems = numberofallitems;
    }        
  }

  ngOnInit() {
    this.categoryservice.getAllCategories((res) => {
      this.categories = res.body;
      this.loadState();
      this.getArticles();
    });
  }

}
