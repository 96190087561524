import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpHeaderResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Article } from './models/NSwagGeneratedClasses';
import { EntityWithPagination } from './models/EntityWithPagination';
import { AuthService } from './auth.service';
import { ErrorhandlerService } from './errorhandler.service';
import { concatMap, switchMap } from 'rxjs/operators';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  constructor(private http: HttpClient, private authservice: AuthService, private errorhandler: ErrorhandlerService) { }

  articleUrl: string = "api/Article";

  //Article listához lekérdezés
  getArticles(term: string = "", pagesize: number, orderby: string, orderdirection: number, category: number,
    createdDateFrom: Date, createdDateTill: Date, publicationDateFrom: Date, publicationDateTill: Date, author: string,
    page: number, callback: Function) {
    //let catIds: string;    
    //catIds = categories.join(";");

    this.authservice.GetAuthHeader().pipe(
      switchMap(val => this.http.get<EntityWithPagination<Article>>(this.articleUrl + "/?term=" + (term ? term : "") +
        "&categories=" + (category ? category : "") +
        "&publicationdatefrom=" + (publicationDateFrom ? moment(publicationDateFrom).format("YYYY.MM.DD") : "") +
        "&publicationdatetill=" + (publicationDateTill ? moment(publicationDateTill).format("YYYY.MM.DD") : "") +
        "&creationdatefrom=" + (createdDateFrom ? moment(createdDateFrom).format("YYYY.MM.DD") : "") +
        "&creationdatetill=" + (createdDateTill ? moment(createdDateTill).format("YYYY.MM.DD") : "") +
        "&author=" + (author ? author : "") + "&pagesize=" + pagesize + "&orderby=" + orderby +
        "&orderdirection=" + orderdirection + "&page=" + page, { headers: val, observe: 'response' }))).
      subscribe(res => { callback(res); },
      error => { this.errorhandler.Handle(error); }
      );
  };

  getArticlesSearchEvents(term: string = "", pagesize: number, orderby: string, orderdirection: number, page: number, group: boolean, from: string, till: string, callback: Function) {
    this.authservice.GetAuthHeader().pipe(
      concatMap(val => this.http.get<EntityWithPagination<object>>(this.articleUrl + "/SearchArticleEvents?term=" + term + "&pagesize=" + pagesize + "&page=" + page + "&from=" + from + "&till=" + till + "&group=" + group, { headers: val, observe: 'response' }))).
      subscribe(res => { callback(res); },
        error => { this.errorhandler.Handle(error); }
      );
  };

  getArticle(Id: number, callback: Function) {
    this.authservice.GetAuthHeader().pipe(
    concatMap(val=>this.http.get<Article>(this.articleUrl + "/GetById?Id=" + Id, { headers: val, observe: 'response' }))).
      subscribe(
      res => { callback(res); },
        error => { this.errorhandler.Handle(error); });
  }

  getArticleTitleById(Id: number, callback: Function) {
    this.authservice.GetAuthHeader().pipe(
    concatMap(val=>this.http.get<string>(this.articleUrl + "/GetTitleById?Id=" + Id, { headers: val, observe: 'response' }))).
      subscribe((res) => { callback(res); }, error => { this.errorhandler.Handle(error); })
  }

  getRelatedArticlesWithTitle(Id: number, callback: Function) {
    this.authservice.GetAuthHeader().pipe(
    concatMap(val=>this.http.get<object[]>(this.articleUrl + "/GetRelatedArticlesWithTitle?Id=" + Id, { headers: val, observe: 'response' }))).
      subscribe((res) => { callback(res); }, error => { this.errorhandler.Handle(error); })
  }

    getAlias(Title: string = ""): Observable<HttpResponse<object>> {        
        return this.authservice.GetAuthHeader().pipe(
            concatMap(val => this.http.get<object>(this.articleUrl + "/GetArticleAlias?Title=" + Title, { headers: val, observe: 'response' })))
  }

  saveArticle(article: Article, callback: Function) {
    this.authservice.GetAuthHeader().pipe(
    concatMap(val=>this.http.post<Article>(this.articleUrl + "/SaveArticle", article, { headers: val, observe: 'response' }))).
      subscribe(res => { callback(res); },
        error => { this.errorhandler.Handle(error); })
    };

    getAllComments(articleId: number, state:number, pagesize: number, page: number, callback: Function) {
      this.authservice.GetAuthHeader().pipe(
          concatMap(val => this.http.get<object>("/api/Article/GetAllComments?ArticleId=" + articleId + "&state=" + state +"&pagesize=" + pagesize + "&page=" + page, { headers: val, observe: 'response' }))).
          subscribe(res => {
              callback(res);
          })
    }

    DeleteComment(articleId: number, callback: Function) {
        this.authservice.GetAuthHeader().pipe(
            concatMap(val => this.http.get<JSON>("/api/Article/DeleteComment?Id=" + articleId, { headers: val, observe: 'response' }))).
            subscribe(res => {
                callback(res);
            })
    }

    SwitchState(articleId: number, callback: Function) {
        this.authservice.GetAuthHeader().pipe(
            concatMap(val => this.http.get<JSON>("/api/Article/SwitchCommentState?Id=" + articleId, { headers: val, observe: 'response' }))).
            subscribe(res => {
                callback(res);
            })
    }
}
