import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationExtras } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let navigationExtras: NavigationExtras = {
      queryParams: { 'url': state.url }}
    
    if (this.authservice.isLoggedIn() || (this.authservice.getCurrentUser() !== null && moment(this.authservice.getCurrentUser().refreshTokenValidity).toDate() > moment().toDate()))
    {
      return true;
    }
    else {
      this.router.navigate(['login'], navigationExtras);
      this.toastr.error("Lejárt munkamenet!");
      return false;
    }
    return true;
  }

  constructor(private router: Router, private authservice: AuthService, private toastr: ToastrService) { }
}
