import { Component, OnInit } from '@angular/core';
import { EntityWithPagination } from '../models/EntityWithPagination';
import { CategoryService } from '../category.service';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { PaginationComponent } from '../pagination/pagination.component';
import { AuthService } from '../auth.service';
import { Article, Category } from '../models/NSwagGeneratedClasses';
import { ArticleService } from '../article.service';
import { PageEvent } from '@angular/material';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {

  //pageEvent: PageEvent;
  
  categories: Category[];  
  term: string = "";
  orderby: string = "Name";
  orderdirection: number = 1;

  numberofallitems: number;
  pagesize: number = 10;
  page: number = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  numberofpages;
    
  //getCategories(): void {
  //  this.categoryService.getCategories(this.term, this.pagesize, this.orderby, this.orderdirection, this.page).subscribe((res) =>
  //  {
  //    if (res.ok) {
  //      this.categories = res.body.results;
  //      this.numberofpages = Math.ceil(res.body.count / this.pagesize);        
  //    }
  //    else {
  //      this.toastr.error("Nem sikerült a kategóriák lekérdezése!");
  //    }
  //    },
  //    error => {        
  //      this.toastr.error("Nem sikerült a kategóriák lekérdezése!");
  //    });    
  //}

  getCategories(): void {
    this.categories = null;
    this.categoryService.getCategories(this.term, this.pagesize, this.orderby, this.orderdirection, this.page, (res) => {
      if (res.ok) {
        this.categories = res.body.results;
        this.numberofpages = Math.ceil(res.body.count / this.pagesize);
        this.numberofallitems = res.body.count;
      }
    });      
  }

  paginationHandler(event: PageEvent) {
    //if (page == 0 && this.page < this.numberofpages) {
    //  this.page++;
    //  this.getArticles();
    //}
    //else if (page == -1 && this.page > 1) {
    //  this.page--;
    //  this.getArticles();
    //}
    //else {
    //  this.page = page;
    //  this.getArticles();
    //}
    this.page = event.pageIndex;
    this.pagesize = event.pageSize;
    this.getCategories();
  }

  addCategory(newCategory: string) {
    //var found = this.categories.filter(x => x.name == newCategory);

    //if (found == null || found.length == 0) {
      this.categoryService.addCategory({ name: newCategory, id: 0 } as Category, (res) => {
        if (res.ok) {
          this.toastr.success("Kategória hozzáadva!");
          this.getCategories();
        }
      })
    //}
    //else {
    //  this.toastr.warning("A megadott kategória már létezik!");
    //}
  }

  setOrdering(colName: string) {
    this.orderby = colName;
    if (this.orderdirection == 1) { this.orderdirection = 2; } else { this.orderdirection = 1; }
    this.getCategories();
  }

  delCategory(Id: number) {
    this.categoryService.delCategory(Id, (res) => {
                if (res.ok) {
                  this.categories = this.categories.filter(x => x.id !== Id);
                  this.toastr.success("Kategória törölve!");
                  this.getCategories();
                }
                });    
  }

  constructor(private categoryService: CategoryService, private toastr: ToastrService, private authservice: AuthService) { }

  ngOnInit() {
    this.getCategories();
    //console.log("Article-Categories Oninit Called!");
  }

}
