import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpHeaderResponse } from '@angular/common/http';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable ,  Subject, of, interval, concat, Subscription } from 'rxjs';
import * as moment from 'moment';
import "rxjs/add/observable/defer";
import "rxjs/add/observable/of";
import "rxjs/add/operator/multicast";
import { CurrentUser } from './models/CurrentUser';
import { map, concatMap, tap, flatMap, mergeMap, switchMap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loginUrl = 'api/Account';

  private notifyLoggedin = interval(360 * 1000)
    .pipe(
      concatMap(val=>this.GetAuthHeader()),
      concatMap(val=>this.http.get(this.loginUrl + "/NotifyLoggedin?username=" + this.getCurrentUser().username, { headers: val, observe: 'response' })));

  private notifyLoggedinSubscription: Subscription;

  getCurrentUser(): CurrentUser {
    return JSON.parse(localStorage.getItem('CurrentUser'));
  }

  saveCurrentUser(user: CurrentUser) {
    localStorage.setItem("CurrentUser", JSON.stringify(user)); 
  }

  isLoggedIn(): boolean {
    let result = false;
    let now = moment();
    let user: CurrentUser = this.getCurrentUser();    
        
    if (user != null && user.token != null && user.token.length > 0 && moment(user.tokenvalidity).toDate() > now.toDate()) {
      result = true;
    }

    return result;
  }
  
  loginResult: string;
  tokenvalidityspan: number;

  Login(username: string, password: string, redirectUrl: string = '') {
    var result: boolean;    
    this.http.post<JSON>(this.loginUrl + "/RequestToken", { 'username': username, 'password': password }, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' }).subscribe((res) => {
      if (res.ok) {
        this.handleLoginResponse(res, username);
        this.loginResult = '';
        this.router.navigateByUrl(redirectUrl);        
      }
      else {
        this.loginResult = 'Ismeretlen felhasználónév vagy jelszó';
      }
    }, error => {
      this.loginResult = 'Sikertelen bejelentkezés!';
    });
  }

  handleLoginResponse(response: HttpResponse<JSON>, username: string) {
    let user: CurrentUser = {} as CurrentUser;
    user.username = username;
    user.token = response.body['token'];
    user.tokenvalidity = response.body['tokenValidity'];
    user.refreshToken = response.body['refreshToken'];
    user.refreshTokenValidity = response.body['refreshTokenValidity'];
    console.log("token validity: " + user.tokenvalidity);
    console.log("refresh token validity: " + user.refreshTokenValidity);
    if (this.notifyLoggedinSubscription == undefined || this.notifyLoggedinSubscription.closed == true) {
      this.notifyLoggedinSubscription = this.notifyLoggedin.subscribe(val => console.log("Notified server about loggedin state at " + moment().toDate().toString()));
    }

    this.saveCurrentUser(user);

    return response;
  }



  getNewTokenFromRefreshToken(): Observable<HttpResponse<JSON>> {
    //console.log("token expired requesting new token based on refreshtoken");
    
    return this.http.post<JSON>(this.loginUrl + "/RefreshToken", { RefreshToken: this.getCurrentUser().refreshToken, Username: this.getCurrentUser().username }, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' });
  }

  Logout() {
    localStorage.clear();
    this.loginResult = '';    
    this.notifyLoggedinSubscription.unsubscribe();
    this.router.navigateByUrl('login');
  }

  GetAuthHeader(): Observable<HttpHeaders> {    
    if (this.isLoggedIn()) {
      return of(new HttpHeaders({ 'Authorization': 'Bearer ' + this.getCurrentUser().token }));
    }
    else if (this.getCurrentUser() != null && moment(this.getCurrentUser().refreshTokenValidity).toDate() > moment().toDate()) {
      return this.getNewTokenFromRefreshToken().pipe(
        switchMap(val => of(this.handleLoginResponse(val, this.getCurrentUser().username))),
        switchMap(val => of(new HttpHeaders({ 'Authorization': 'Bearer ' + val.body["token"] }))))
    }
  }

  constructor(private http: HttpClient, private router: Router) { }
}
