import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CategoriesComponent } from './categories/categories.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { LoginComponent } from './login/login.component';
import { ArticleComponent } from './article/article.component';
import { ArticleEditorComponent } from './article-editor/article-editor.component';
import { AuthGuard } from './auth/auth.guard';
import { SimplepageComponent } from './simplepage.component';
import { TemplatepageComponent } from './templatepage.component';
import { PollsComponent } from './polls/polls.component';
import { PollEditorComponent } from './poll-editor/poll-editor.component';
import { UsersComponent } from './users/users.component';
import { FormsComponent } from './forms/forms.component';
import { CommentsComponent } from './comments/comments.component';
import { ArticlesearcheventsComponent } from './articlesearchevents/articlesearchevents.component';

const routes: Routes =
  [
    //{ path: '', redirectTo: 'login', pathMatch: 'full' },    
    {
      path: '',
      component: TemplatepageComponent,
      children: [
              { path: '', redirectTo: 'articles', pathMatch: 'full', canActivate: [AuthGuard] },                  
              { path: 'categories', component: CategoriesComponent, canActivate: [AuthGuard] },    
              { path: 'articles', component: ArticleComponent, canActivate: [AuthGuard] },
              { path: 'articles/:id', component: ArticleEditorComponent, canActivate: [AuthGuard] },
              { path: 'polls', component: PollsComponent, canActivate: [AuthGuard] },
              { path: 'polls/:id', component: PollEditorComponent, canActivate: [AuthGuard] },
              { path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
              { path: 'searchevents', component: ArticlesearcheventsComponent, canActivate: [AuthGuard] },
              { path: 'forms', component: FormsComponent, canActivate: [AuthGuard] },
              { path: 'comments', component: CommentsComponent, canActivate: [AuthGuard] }
                 ]
    },
    {
      path: '',
      component: SimplepageComponent,
      children: [
              { path: 'login', component: LoginComponent }
               ]
    },
    { path: '*', component: PagenotfoundComponent },
    
  ]

@NgModule({
  exports: [
    RouterModule
  ],
  imports: [    
    RouterModule.forRoot(routes)
  ],
  declarations: []
})
export class AppRoutingModule { }
