import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigurationService } from '../configuration.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private authservice: AuthService, private route: ActivatedRoute) {
    
  }

  defaultUrl: string = 'articles';
  redirectUrl: string;
  username: string;
  password: string;    
    
  Login() {
    localStorage.clear();
    this.authservice.Login(this.username, this.password, this.redirectUrl);
  }

  ngOnInit() {
    this.route
      .queryParamMap
      .pipe(map(params => params.get('url') || this.defaultUrl)).subscribe((res) => {
        this.redirectUrl = res;
      });
  }
}
