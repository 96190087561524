import { Component, OnInit } from '@angular/core';
import { TestCategory, Test, TestResultRange, TestQuestion, TestQuestionType } from '../models/NSwagGeneratedClasses';
import { FormService } from '../form.service';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.css']
})
export class FormsComponent implements OnInit {

  private testCategories: TestCategory[];
  selectedCategory: TestCategory;
  private testQuestionTypes: TestQuestionType[];

  private tests: Test[];
  private selectedTest: Test;

  private testQuestions: TestQuestion[];
  private testResultRanges: TestResultRange[];

  selectedCategoryChanged() {
    if (this.selectedCategory !== null) {
      this.formservice.getForms(this.selectedCategory.id, (res) => {
        this.tests = res.body;
        this.selectedTest = null;
        this.testResultRanges = null;        
      });
    }
  }

  selectedTestChanged() {
    this.formservice.getQuestionTypes(res => {
      this.testQuestionTypes = res.body;
      console.log(res.body);
    });

    if (this.selectedCategory !== null) {
      this.formservice.getResultRanges(this.selectedTest.id, (res) => {
        this.testResultRanges = res.body;
      });

      this.formservice.getQuestions(this.selectedTest.id, (res) => {
        this.testQuestions = res.body;        
      })
    }
  }

  newResultRange() {
    this.testResultRanges.push({ testId: this.selectedTest.id, id:0, minPoints:0, maxPoints:0,resultText:"" } as TestResultRange);
  }

  newQuestion() {
    this.testQuestions.push({ testId: this.selectedTest.id, id: 0, ordering: 0, published: false, text:"", displayRightAnswerOnNext: false, testQuestionTypeId: 1 } as TestQuestion);
  }

  removeResultRange(resultrange: TestResultRange) {
    this.testResultRanges = this.testResultRanges.filter(x => x.id != resultrange.id);
  }

  removeQuestion(question: TestQuestion) {
    this.testQuestions = this.testQuestions.filter(x => x.id != question.id);
  }

  editorSettings() {
    return {
      skin_url: '/assets/tinymce/skins/lightgray',
      inline: false,
      statusbar: false,
      browser_spellcheck: true,
      entity_encoding: 'raw',
      height: 300,
      toolbar: ['image', 'media', 'table', 'fullscreen', 'forecolor', 'backcolor', 'code', 'bold', 'underline', 'italic', 'link'],
      plugins: ['fullscreen', 'image', 'imagetools', 'table', 'paste', 'autolink', 'textcolor', 'link', 'media', 'code', 'hr'],
      image_advtab: true,
      paste_data_images: true,
      relative_urls: true,
      allow_script_urls: true,
      convert_urls: false,
      image_class_list: [
        { title: 'None', value: '' },
        { title: 'Balra igazított', value: 'article_img_left' },
        { title: 'Jobbra igazított', value: 'article_img_right' }
      ],
      contextmenu: ['link', 'image', 'bold', 'underline', 'italic'],
      language: 'hu_HU',
      //content_css: "/my-styles.css",
      color_map: [
        "000000", "Black",
        "993300", "Burnt orange",
        "333300", "Dark olive",
        "003300", "Dark green",
        "003366", "Dark azure",
        "000080", "Navy Blue",
        "333399", "Indigo",
        "333333", "Very dark gray",
        "800000", "Maroon",
        "FF6600", "Orange",
        "808000", "Olive",
        "008000", "Green",
        "008080", "Teal",
        "0000FF", "Blue",
        "666699", "Grayish blue",
        "808080", "Gray",
        "FF0000", "Red",
        "FF9900", "Amber",
        "99CC00", "Yellow green",
        "339966", "Sea green",
        "33CCCC", "Turquoise",
        "3366FF", "Royal blue",
        "800080", "Purple",
        "999999", "Medium gray",
        "FF00FF", "Magenta",
        "FFCC00", "Gold",
        "FFFF00", "Yellow",
        "00FF00", "Lime",
        "00FFFF", "Aqua",
        "00CCFF", "Sky blue",
        "993366", "Red violet",
        "FFFFFF", "White",
        "FF99CC", "Pink",
        "FFCC99", "Peach",
        "FFFF99", "Light yellow",
        "CCFFCC", "Pale green",
        "CCFFFF", "Pale cyan",
        "99CCFF", "Light sky blue",
        "CC99FF", "Plum"
      ],
      table_class_list: [
        { title: 'None', value: '' },
        { title: 'Megjegyzés box', value: 'author_message' }
      ]
    };
  }

  constructor(private formservice: FormService) { }

  ngOnInit() {
    this.formservice.getFormCategories((res) => {
      if (res.ok) {
        this.testCategories = res.body;
      }
    });
  }


}
