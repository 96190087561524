import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, PageEvent } from '@angular/material';
import { Router } from '@angular/router';
import { ArticleService } from '../article.service';
import { Article } from '../models/NSwagGeneratedClasses';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-relatedarticleselector',
  templateUrl: './relatedarticleselector.component.html',
  styleUrls: ['./relatedarticleselector.component.css']
})
export class RelatedarticleselectorComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RelatedarticleselectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: object, private articleservice: ArticleService, private router: Router, private fb: FormBuilder) { }

  

  articles: Article[];
  term: string = "";
  orderby: string = "";
  orderdirection: number = 2;

  pagesize: number = 10;
  page: number = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  numberofpages;
  numberofallitems: number;
  seachfinished: boolean = false;

  searchForm: FormGroup = this.fb.group({
    term: new FormControl(),
    category: new FormControl(),
    createdDateFrom: new FormControl(),
    createdDateTill: new FormControl(),
    publicationDateFrom: new FormControl(),
    publicationDateTill: new FormControl(),
    author: new FormControl()
  })

  @Output() articleSelected = new EventEmitter<Article>();

  addArticleAsRelated(article: Article) {
    //this.data.selectedArticle = article;
    //console.log(this.data.sourceArticleId);
    //console.log(this.data.selectedArticle);
    this.articleSelected.emit(article);
    //this.dialogRef.close();
  }

  clearSearch() {
    this.searchForm.reset();
    this.getArticles();
  }

  search() {
    console.log(this.searchForm.value);
    this.page = 0;
    this.getArticles()
  }

  getArticles() {
    this.seachfinished = false;
    this.articleservice.getArticles(this.searchForm.get('term').value, this.pagesize, this.orderby, this.orderdirection, this.searchForm.get('category').value,
      this.searchForm.get('createdDateFrom').value, this.searchForm.get('createdDateTill').value,
      this.searchForm.get('publicationDateFrom').value, this.searchForm.get('publicationDateTill').value,
      this.searchForm.get('author').value, this.page, res => {
        if (res.ok) {          
          this.articles = res.body.results;
          this.numberofpages = Math.ceil(res.body.count / this.pagesize);
          this.numberofallitems = res.body.count;
          this.seachfinished = true;          
        }
      });
  }

  paginationHandler(event: PageEvent) {
    //if (page == 0 && this.page < this.numberofpages) {
    //  this.page++;
    //  this.getArticles();
    //}
    //else if (page == -1 && this.page > 1) {
    //  this.page--;
    //  this.getArticles();
    //}
    //else {
    //  this.page = page;
    //  this.getArticles();
    //}
    this.page = event.pageIndex;
    this.pagesize = event.pageSize;
    this.getArticles();
  }

  ngOnInit() {
    this.getArticles();
  }

}
