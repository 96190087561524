import { Component, OnInit } from '@angular/core';
import { PollWithVoteCount, PollOption } from '../models/NSwagGeneratedClasses';
import { PollService } from '../poll.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-poll-editor',
  templateUrl: './poll-editor.component.html',
  styleUrls: ['./poll-editor.component.css']
})
export class PollEditorComponent implements OnInit {

  constructor(private pollservice: PollService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router) { }

  Poll: PollWithVoteCount = {} as PollWithVoteCount;

  getPoll(Id: number) {
    this.pollservice.getPoll(Id, (res) => {
      if (res.ok) {
        this.Poll = res.body;
        //console.log(this.Poll);
      }
    })
  }

  AddOption(option: string) {
    this.Poll.pollOptions.push({ text: option } as PollOption)
  }

  SavePoll() {
    //this.Poll.pollOptions = this.Poll.pollOptions.filter(x => x.id !== 177);
    this.pollservice.savePoll(this.Poll, (res) => {
      this.toastr.success("Sikeres mentés!");      
        this.getPoll(+res.body);      
    });
  }

  ShowContent() {
    console.log(this.Poll);
  }
  ngOnInit() {
    this.route.paramMap.subscribe(param => {
      let id = +param.get('id');
      if (id !== 0) {
        this.getPoll(id);
      }
      else {
        this.Poll.pollOptions = [];
      }
    })
    
  }

}
